import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';  // Import useAuth hook
import { refreshToken } from './firebaseInit';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

function HomeScreen() {
  const [username, setUsername] = useState('Guest');
  const navigate = useNavigate();
  const { user, loading, getToken } = useAuth();

  useEffect(() => {
    if (!loading && user) {
      const fetchUsername = async () => {
        try {
          const token = await getToken();
          const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/byUid/${user.uid}`;
          const response = await fetch(apiUrl, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            setUsername(data.username);
          } else {
            console.error('Failed to fetch username');
            setUsername('Guest');
          }
        } catch (error) {
          console.error('Error fetching username:', error);
          setUsername('Guest');
        }
      };

      fetchUsername();
    } else {
      setUsername('Guest');
    }
  }, [user, loading, getToken]);

  return (
    <Container>
      <Title>Welcome, {username}!</Title>
      <p>Explore the sections of your app:</p>
      <Button onClick={() => navigate('/text-editor')}>Go to Text Editor</Button>
      <Button onClick={() => navigate('/users')}>Go to Users</Button>
      <Button onClick={() => navigate('/login')}>Login</Button>
      <Button onClick={() => navigate('/register')}>Register</Button>
      <Button onClick={() => navigate('/chatrooms')}>Chatrooms</Button>
      <Button onClick={() => navigate('/moodboard')}>Moodboard</Button>
      <Button onClick={() => navigate('/feed')}>Editor Feed</Button>
    </Container>
  );
}

export default HomeScreen;