import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebaseInit';
import ThemeContext from './ThemeContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  max-width: 940px;  // Maintain the maximum width as 940px
  margin: 0 auto;  // Center the container horizontally
  width: 100%;  // Ensure it fills the space up to the maximum width
  align-items: center;  // Center content horizontally within the container
  justify-content: center;  // Center content vertically within the container
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;  // Set to flex to control the layout more effectively
  flex-direction: column;  // Stack children vertically
  align-items: center;
`;

const Input = styled.input`
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;  // Removes the border
  outline: none;  // Removes the outline on focus
  font-size: ${({ theme }) => theme.text.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.mediumText.fontWeight};
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const MenuItem = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.mediumText.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.mediumText.fontFamily};
  color: #000;  // Color of the text
  cursor: pointer;
  background-color: transparent;  // Remove any background color
  border: none;  // Remove borders
  border-radius: 0;  // Remove border radius

  // Remove hover effect
  &:hover {
    background-color: transparent;
  }

  // Focus styles for accessibility, no visual change when clicked
  &:focus {
    outline: none;
  }
`;

const TermsContainer = styled.div`
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

const TermsText = styled.p`
  margin: 0;
  font-size: 14px;
`;

const TermsLink = styled.a`
  margin-left: 5px;
  font-size: 14px;
  color: #007AFF;
  cursor: pointer;
  text-decoration: none;
`;

const RegisterScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    try {
      console.log('Attempting to register:', email);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Registration successful:', userCredential);
      alert('User registered successfully');
      navigate('/set-username');
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Registration Error: ' + error.message);
      if (error.code === 'auth/network-request-failed') {
        console.error('Please check your network connection');
      }
    }
  };

  return (
    <Container>
      <InputContainer>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </InputContainer>
      <Menu>
        <MenuItem onClick={() => navigate('/chatrooms')}>
          Close
        </MenuItem>
        <MenuItem onClick={handleRegister}>
          Sign Up
        </MenuItem>
      </Menu>
      <TermsContainer>
        <TermsText>By signing up, you agree to our</TermsText>
        <TermsLink onClick={() => navigate('/terms')}>Terms of Service</TermsLink>
      </TermsContainer>
    </Container>
  );
};

export default RegisterScreen;