import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import io from 'socket.io-client';
import { useAuth } from './AuthContext'; // Adjust the path as necessary
import OverlayComponent from './OverlayComponent';
import ThemeContext from './ThemeContext';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import ImageIcon from '@mui/icons-material/Image';
import MoreIcon from '@mui/icons-material/MoreHoriz';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Container = styled.div`
  //height: 100vh;
  //position: absolute;
  //bottom: 0;
  //max-width: 930px;
  //margin: 0 auto;
  //width: 100%;
  //overflow: hidden;  // This prevents scrolling outside the bounds
`;

const ToggleContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 100;
`;

const ToggleButton = styled.div`
  //background-color: white;
  //padding: 4px;
  //border-radius: 50%;
  //border: 2px solid black;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  cursor: pointer;
`;

const CommentInputContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  //padding: 0 5px;
  padding-top: 5px;
  align-items: center;
  //background-color: pink;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  background-color: white;
  padding: 10px;
  border: none;  // Removes the border
  outline: none;  // Removes the outline on focus
  margin-right: 10px;
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.text.fontWeight};
  font-size: 13px;
  
`;
const SendButton = styled.div`
  margin-left: 10px;
  margin-right: 5px;
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.text.fontWeight};
  font-size: 14px;
`;

const Footer = styled.div`
  //position: absolute;
  //bottom: 60px;
  //left: 20px;
  height: 22px;
  //max-width: 500px;
  //margin: 0 auto;
  //border: 1px solid black;
  //background-color: pink;
`;
const Dock = styled.div`
  display: flex;
  flex-direction: row;
  //flex-grow: 1;
  border-top: 1px solid;
  //position: relative;
  height: 100%;
  //width: 100%;
  align-items: center;
`;

const APBarContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;  
  height: 100%;
  //width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  //background-color: pink;
  
`;

const APBar = styled.div`
height: 100%;
background: linear-gradient(
  to right,
  #FF1010 0%,
  #FF5010 10%,
  #FF5010 10%,
  #FF9F10 15%,
  #FF9F10 15%,
  #FFFA52 25%,
  #FFFA52 25%,
  #F1FF4A 27%,
  #F1FF4A 27%,
  #A7FF31 35%,
  #A7FF31 35%,
  #09F4F4 45%,
  #09F4F4 45%,
  #141FF8 58%,
  #141FF8 58%,
  #210AF9 60%,
  #210AF9 60%,
  #A514F8 70%,
  #A514F8 70%,
  #CF0DDE 80%,
  #CF0DDE 80%,
  #EA1080 100%
);
position: absolute;
top: 0;
left: 0;
width: 100%;
`;

const WhiteSlider = styled.div`
  height: 100%;
  //width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
`;

const DockMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;

const APText = styled.div`
font-family: ${({ theme }) => theme.boldText.fontFamily};
font-weight: ${({ theme }) => theme.boldText.fontWeight};
font-size: 14px;
//font-size: ${({ theme }) => theme.boldText.fontSize};
cursor: pointer;
`;

const ChromaImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ChromaImage = styled.img`
  width: 22px;
  height: 22px;
  //resize-mode: contain;
`;

const ChromaText = styled.div`
  margin-left: 5px;
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
  font-size: 14px;
  //font-size: ${({ theme }) => theme.boldText.fontSize};
`;

const ProfileBox = styled.div`
  position: relative;
  cursor: pointer;
`;

const NotificationCircle = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
`;

const QuickBadgeStore = styled.div`
  //width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
`;

const BadgeItemsContainer = styled.div`
  //display: flex;
  //flex-direction: row;
  //height: 35px;
`;

const MiniBadgeImage = styled.img`
  width: 25px;
  height: 25px;
  //object-fit: contain;
  margin-right: 5px;
  cursor: pointer;
`;

const InteractionStateContainer = styled.div`
  //position: absolute;
  //bottom: 150px;
  //left: 20px;
  display: flex;
  flex-direction: row;
//padding: 10px;
  
  align-items: center;
//color: white;
//margin-right: 10px;
`;

const InteractionState = styled.div`
  //color: white;
  //margin-right: 10px;
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.text.fontWeight};
  font-size: 14px;
  //background-color: white;
`;

const ClearButton = styled.div`
  //background: none;
  //border: none;
  cursor: pointer;
`;
const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //padding-bottom: 5px;
  //background: none;
  //border: none;
  //cursor: pointer;
`;
const Tools = styled.div`
  display: flex;
  flex-direction: row;
  //background: none;
  //border: none;
  //cursor: pointer;
`;
const ToolItem = styled.div`
font-family: ${({ theme }) => theme.text.fontFamily};
font-weight: ${({ theme }) => theme.text.fontWeight};
font-size: 14px;
  margin-right: 5px;
  //background: none;
  //border: none;
  cursor: pointer;
`;

const ChatroomInteractions = ({ chatroom, setTarget, target, sendMessage, inputText, setInputText, isHost,
  setShowModal,
  handleStopWatchParty,
  youtubeLink,
  socket,
  chatroomId,
  handleImageSelection,
  fetchMessages,
  fetchChatroomDetails,
  hostDetails }) => {
  const { user, getToken } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const [badges, setBadges] = useState([]);
  const [badgePrices, setBadgePrices] = useState({});
  const [ap, setAP] = useState(chatroom.ap || 0);
  const [apCapacity, setAPCapacity] = useState(chatroom.apCapacity || 100);
  const [chroma, setChroma] = useState(0);
  const [chromaOre, setChromaOre] = useState(0);
  const [level, setLevel] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayType, setOverlayType] = useState(null);
  const [selectedSeal, setSelectedSeal] = useState(null);
  const [isAnon, setIsAnon] = useState(false);
  const [hasNotification, setHasNotification] = useState(true);
  const theme = useContext(ThemeContext);
  const fileInputRef = useRef(null);
  const notificationSocket = useRef(null);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const openOverlay = async (type) => {
    if (type === 'MINI_PROFILE' || type === 'AP_DETAILS' || type === 'BADGE_STORE' || type === 'OPTIONS') {
      await fetchProfileData();
      if (type === 'MINI_PROFILE' && hasNotification) {
        await clearNotifications();
      }
    }
    setOverlayType(type);
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setOverlayType(null);
  };

  useEffect(() => {
    if (isOpen) {
      fetchBadges();
      fetchUserAP();
    }
  }, [isOpen]);

  useEffect(() => {
    const initNotificationSocket = async () => {
      try {
        const token = await getToken();
        console.log('[WebSocket] Fetched token:', token);

        const response = await fetch(`${API_BASE_URL}/notifications/status`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch notification status');
        }

        const data = await response.json();
        setHasNotification(data.hasNotification);
        console.log('[WebSocket] Initial notification status:', data.hasNotification);

        notificationSocket.current = io(API_BASE_URL, {
          withCredentials: true,
          transports: ['websocket', 'polling'],
          auth: { token },
        });

        notificationSocket.current.on('connect', () => {
          console.log('[WebSocket] Connected to server');
        });

        notificationSocket.current.on('connect_error', (error) => {
          console.error('[WebSocket] Connection error:', error);
        });

        notificationSocket.current.on('disconnect', (reason) => {
          console.log('[WebSocket] Disconnected from server:', reason);
        });

        // Update this handler to listen for 'notificationUpdate' instead of 'notification'
        const handleNotificationUpdate = (data) => {
          console.log('[WebSocket] Notification update received:', data);
          setHasNotification(data.hasNotification);
          fetchUserAP(); // Fetch user AP when a notification update is received
        };

        notificationSocket.current.on('notificationUpdate', handleNotificationUpdate);

        return () => {
          notificationSocket.current.off('notificationUpdate', handleNotificationUpdate);
          notificationSocket.current.disconnect();
          console.log('[WebSocket] Socket disconnected');
        };
      } catch (error) {
        console.error('[WebSocket] Error initializing socket or fetching notification status:', error);
      }
    };

    if (user) {
      initNotificationSocket();
    }
  }, [user, getToken]);


  const fetchProfileData = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/users/byUid/${user.uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setProfileData({
        username: data.username,
        level: data.level,
        achievements: data.achievements,
        seals: data.seals,
      });
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const fetchBadges = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/badges`);
      const data = await response.json();
      setBadges(data);
      calculateBadgePrices(data, level, apCapacity);
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  };

  const fetchUserAP = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/users/byUid/${user.uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setAP(data.ap);
      setAPCapacity(data.apCapacity);
      setChroma(data.chroma);
      setChromaOre(data.chromaOre);
      setLevel(data.level);
    } catch (error) {
      console.error('Error fetching user AP:', error);
    }
  };

  const updateUserChroma = (newChroma) => {
    setChroma(newChroma);
  };

  const calculateBadgePrices = (badges, userLevel, apCapacity) => {
    const prices = badges.reduce((acc, badge) => {
      if (badge.currency === 'AP') {
        let costPercentage = 0;
        for (let i = badge.costLevels.length - 1; i >= 0; i--) {
          if (userLevel >= badge.costLevels[i].levelThreshold) {
            costPercentage = badge.costLevels[i].percentage;
            break;
          }
        }
        const apCost = apCapacity * costPercentage;
        acc[badge._id] = `${Math.round((apCost / apCapacity) * 100)}%`;
      } else if (badge.currency === 'Chroma') {
        acc[badge._id] = `${badge.chromaCost} Chroma`;
      }
      return acc;
    }, {});
    setBadgePrices(prices);
  };

  const handleSealSelect = (seal) => setSelectedSeal(seal);

  const handlePurchaseBadge = async (badgeId) => {
    try {
      const token = await getToken();
      const requestBody = { 
        uid: user.uid, 
        badgeId, 
        targetId: target?.id,
        contentType: target?.contentType, 
        isAnon, 
        selectedSeal 
      };

      const response = await fetch(`${API_BASE_URL}/badges/purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setAP(data.ap);
        setChroma(data.chroma);
        console.log('Badge purchase successful:', data);
        if (target?.contentType === 'Message') {
          fetchMessages(token); 
        } else {
          fetchChatroomDetails(token); 
        }
      } else {
        const errorData = await response.json();
        if (errorData.error === 'Insufficient AP') {
          alert('Unable to apply badge: Insufficient AP');
        } else if (errorData.error === 'Insufficient Chroma') {
          alert('Unable to apply badge: Insufficient Chroma');
        } else {
          console.error('Error purchasing badge:', errorData);
        }
      }
    } catch (error) {
      console.error('Error purchasing badge:', error);
    }
  };

  const handleClearTarget = () => {
    setInputText('');
    const firstHostUsername = hostDetails.length > 0 ? hostDetails[0].username : '';
    setTarget({ id: chatroom.id, contentType: 'Chatroom', username: firstHostUsername });
  };

  const clearNotifications = async () => {
    try {
      const token = await getToken();
      await fetch(`${API_BASE_URL}/notifications/clear`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      });
      setHasNotification(false);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  const apPercentage = Math.min((ap / apCapacity) * 100, 100);

  const targetText = target?.username ? `Interacting with ${target.username}'s ${target.contentType.toLowerCase()}` : 'Interacting with chatroom';

  return (
    <>
      <Container>
      {isOpen && (
        <>
          
        <Toolbar>
          <QuickBadgeStore>
            <BadgeItemsContainer>
              {badges.map((badge) => (
                <MiniBadgeImage
                  key={badge._id}
                  src={badge.imageUrl}
                  onClick={() => handlePurchaseBadge(badge._id)}
                />
              ))}
            </BadgeItemsContainer>
            <ToggleButton onClick={() => openOverlay('BADGE_STORE')}>
              <ExpandLessIcon style={{ fontSize: 28 }} />
            </ToggleButton>
            <ToggleButton onClick={() => openOverlay('OPTIONS')}>
              <MoreIcon style={{ fontSize: 26 }} />
            </ToggleButton>
          </QuickBadgeStore>
          <Tools>
          <ToolItem onClick={() => isHost ? setShowModal(true) : alert('Only the host can start the watch party.')}>
            Play
          </ToolItem>
          <ToolItem onClick={() => isHost ? handleStopWatchParty() : alert('Only the host can stop the watch party.')}>
            Stop
          </ToolItem>
          <ToolItem onClick={() => youtubeLink ? socket.emit('requestSync', { roomId: chatroomId }) : alert('There are no active watch parties.')}>
            Sync
          </ToolItem>
            </Tools>
        </Toolbar>

        <Footer>
          <Dock>
            <APBarContainer>
              <APBar/>
              <WhiteSlider style={{ width: `${100 - apPercentage}%` }} />
              <DockMenu>
              <APText onClick={() => openOverlay('AP_DETAILS')}>{Math.round(apPercentage)}%</APText>

                <ChromaImageContainer onClick={() => openOverlay('CHROMA_DETAILS')}>
                  <ChromaImage src="https://rwrldstaging.s3.us-east-2.amazonaws.com/fbf15fb8-6b01-4ca9-bc78-8203a6d59ec4-coins.png" />
                  <ChromaText>{chroma}</ChromaText>
                </ChromaImageContainer>

                <ProfileBox onClick={() => openOverlay('MINI_PROFILE')}>
                  <PersonIcon style={{ fontSize: 23 }} />
                  {hasNotification && <NotificationCircle />}
                </ProfileBox>
                
                <InteractionStateContainer>
                  <InteractionState>{targetText}</InteractionState>
                  {target && target.id !== chatroom.id && (
                    <ClearButton onClick={handleClearTarget}>
                      <CloseIcon style={{ color: 'black' }} />
                    </ClearButton>
                  )}
                </InteractionStateContainer>
              </DockMenu>
            </APBarContainer>
          </Dock>
        </Footer>

        <CommentInputContainer>
          <CommentInput
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Add a message..."
          />
          <input type="file" accept="image/*" onChange={handleImageSelection} style={{ display: 'none' }} ref={fileInputRef} />
              <ImageIcon style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => fileInputRef.current && fileInputRef.current.click()} />
          <SendButton onClick={sendMessage}>Send</SendButton>
        </CommentInputContainer>
        </>
      )}
      <OverlayComponent
        visible={overlayVisible}
        type={overlayType}
        data={{ ap, apCapacity, chroma, chromaOre, ...profileData }}
        target={target}
        onClose={closeOverlay}
        badges={badges}
        badgePrices={badgePrices}
        handlePurchaseBadge={handlePurchaseBadge}
        setAnonFlag={setIsAnon}
        isAnon={isAnon}
        selectedSeal={selectedSeal}
        onSealSelect={handleSealSelect}
        updateUserChroma={updateUserChroma}
      />
      </Container>
    </>
    
  );
};

export default ChatroomInteractions;
