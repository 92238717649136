import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

const PaymentForm = ({ clientSecret, paypalOrderId, onSuccessfulPayment, onSuccessfulPayPalPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isPayPal, setIsPayPal] = useState(false);
  const [paypalError, setPaypalError] = useState(null);
  const [email, setEmail] = useState('');

  const handleStripeSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Submit the elements form
    const elementsSubmitResult = await elements.submit();

    if (elementsSubmitResult.error) {
      console.error(elementsSubmitResult.error.message);
      alert('Payment failed. Please try again.');
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret, // Add the clientSecret here
      elements,
      confirmParams: {
        return_url: window.location.href,
        receipt_email: email,
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error(error);
      alert('Payment failed. Please try again.');
    } else if (paymentIntent.status === 'succeeded') {
      onSuccessfulPayment(paymentIntent.id);
    }
  };

  const handlePayPalApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      onSuccessfulPayPalPayment(details);
    } catch (error) {
      console.error('Error capturing PayPal order:', error);
      alert('Purchase failed. Please try again.');
    }
  };

  return (
    <FormContainer>
      <PaymentToggle>
        <Button onClick={() => setIsPayPal(false)}>Card/Wallet</Button>
        <Button onClick={() => setIsPayPal(true)}>PayPal</Button>
      </PaymentToggle>
      {isPayPal ? (
        paypalOrderId ? (
          <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <PayPalButtons
              createOrder={() => Promise.resolve(paypalOrderId)}
              onApprove={handlePayPalApprove}
              onError={(err) => {
                console.error('PayPal error:', err);
                setPaypalError("There was an error with PayPal. Please try another payment method.");
              }}
            />
          </PayPalScriptProvider>
        ) : (
          <ErrorMessage>PayPal is currently unavailable. Please use another payment method.</ErrorMessage>
        )
      ) : (
       
          <form onSubmit={handleStripeSubmit}>
          <PaymentElementContainer>
            <PaymentElement />
          </PaymentElementContainer>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <AddressElementContainer>
            <AddressElement options={{ mode: 'billing' }} />
          </AddressElementContainer>
          
          <PayButton type="submit" disabled={!stripe}>
            Pay
          </PayButton>
          </form>
      
      )}
      {paypalError && <ErrorMessage>{paypalError}</ErrorMessage>}
    </FormContainer>
  );
};


const FormContainer = styled.div`
  max-width: 400px;
  max-height: 600px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0 auto;
  padding: 30px; /* Add some padding for better layout */
  //border: 1px solid #ccc;
  //border-radius: 5px;
  //background-color: #f9f9f9;
`;

const PaymentToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const AddressElementContainer = styled.div`
  margin-bottom: 20px;
`;

const PaymentElementContainer = styled.div`
  margin-bottom: 20px;
`;

const PayButton = styled.button`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

export default PaymentForm;
