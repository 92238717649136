import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { signOut } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { auth } from './firebaseInit';
import ThemeContext from './ThemeContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  max-width: 940px;  // Sets the maximum width to 600px; adjust as needed
  margin: 0 auto;  // Centers the container horizontally
  width: 100%;  // Ensures it fills the space up to the maximum width
`;

const Jumbotron = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  margin-top: 5px;
  padding-bottom: 20px;
`;
const BoldText = styled.div`
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
  font-size: ${({ theme }) => theme.boldText.fontSize};
`;

const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ChatroomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Item = styled.div`
  padding: 30px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 25px;
`;

const MenuItem = styled.div`
  padding: 0 30px;
  cursor: pointer;
`;

const AuthContainer = styled.div`
padding-bottom: ${({ isAuthenticated }) => isAuthenticated ? '20px' : '15px'};
  border-top: 1px solid #000;
`;

const UserStatus = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 0;
`;

const BoldTextSmall = styled.div`
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
  font-size: ${({ theme }) => theme.text.fontSize};
`;

const Modal = styled.div`
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;  // Removes the border
  outline: none;  // Removes the outline on focus
  font-size: ${({ theme }) => theme.text.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.mediumText.fontWeight};
`;

const ModalMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const ModalMenuItem = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.mediumText.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.mediumText.fontFamily};
  color: #000;  // Color of the text
  cursor: pointer;
  background-color: transparent;  // Remove any background color
  border: none;  // Remove borders
  border-radius: 0;  // Remove border radius

  // Remove hover effect
  &:hover {
    background-color: transparent;
  }

  // Focus styles for accessibility, no visual change when clicked
  &:focus {
    outline: none;
  }
`;

const ChatroomsPage = () => {
  const [chatrooms, setChatrooms] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [chatroomName, setChatroomName] = useState('');
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [userRoles, setUserRoles] = useState([]);

  const { user, loading, getToken } = useAuth();

  useEffect(() => {
    if (!loading) {
        if (user) {
            fetchUsername(user);
            setIsAuthenticated(true);  // Set isAuthenticated to true when user is logged in
            console.log("User is authenticated");
        } else {
            setIsAuthenticated(false);  // Set isAuthenticated to false when no user is logged in
            setUsername('please login or sign up to participate');
            console.log("User is not authenticated");
        }
    }
}, [user, loading]);


  
  const fetchUsername = async (user) => {
    try {
      const token = await getToken();
      const apiUrl = `${API_BASE_URL}/users/byUid/${user.uid}`;
      const relationalApiUrl = `${API_BASE_URL}/relational-user`;
    
      console.log('Fetching from:', apiUrl);
      console.log('Fetching from:', relationalApiUrl);

      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const userData = await response.json();
        setUsername(userData.username);
        setUserId(user.uid);
      } else {
        console.error('Failed to fetch username:', await response.text());
        setUsername('Guest');
      }
      // Fetch relational user data
    const relationalResponse = await fetch(relationalApiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (relationalResponse.ok) {
      const relationalUserData = await relationalResponse.json();
      setUserRoles(relationalUserData.roles || []);
    } else {
      console.error('Failed to fetch user roles:', await relationalResponse.text());
      setUserRoles([]);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    setUsername('Guest');
    setUserRoles([]);
  }
};
  

  useEffect(() => {
    console.log(`${API_BASE_URL}/chatrooms`);
    fetchChatrooms();
  }, []);

  const fetchChatrooms = async () => {
    console.log("Fetching chatrooms from:", `${API_BASE_URL}/chatrooms`);
    try {
        const response = await fetch(`${API_BASE_URL}/chatrooms`);
        if (response.ok) {
            const data = await response.json();
            console.log("Fetched chatrooms:", data);
            setChatrooms(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        } else {
            console.error('Failed to fetch chatrooms:', response.status, await response.text());
        }
    } catch (error) {
        console.error('Failed to fetch chatrooms:', error);
    }
};



  const handleSelectChatroom = (chatroom) => {
    navigate(`/chatrooms/${chatroom._id}`, { state: { chatroomName: chatroom.name } });
  };

  const createChatroom = async () => {
    if (!user) {
        console.error('User not authenticated');
        alert('Please log in to create chatrooms.');
        return;
    }

    try {
      const token = await getToken();  // Get ID token directly from the user object
        const response = await fetch(`${API_BASE_URL}/chatrooms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Use the token for authorization
            },
            body: JSON.stringify({
                name: chatroomName,
                hosts: [user.uid]
            })
        });

        if (!response.ok) {
            throw new Error('HTTP error! Failed to create chatroom');
        }

        const newChatroom = await response.json();
        setModalVisible(false);
        setChatroomName('');
        fetchChatrooms();  // Refresh the list of chatrooms
        handleSelectChatroom(newChatroom);  // Navigate to the new chatroom
    } catch (error) {
        console.error('Failed to create chatroom:', error.message);
        alert('Failed to create chatroom: ' + error.message);
    }
};

const deleteChatroom = async (chatroomId) => {
  if (!user) {
    console.error('User not authenticated');
    return;
  }

  try {
    const token = await getToken();
    const response = await fetch(`${API_BASE_URL}/chatrooms/${chatroomId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error! Failed to delete chatroom');
    }
    fetchChatrooms(); // Refresh the list of chatrooms after deletion
  } catch (error) {
    console.error('Failed to delete chatroom:', error.message);
  }
};

const handleLogout = async () => {
  try {
    await signOut(auth); // Use auth from firebaseInit
    console.log("Logout successful");
    navigate('/chatrooms');
  } catch (error) {
    console.error('Error logging out:', error);
    alert('Logout Error: ' + error.message);
  }
};

const theme = useContext(ThemeContext);
  return (
    <Container>
      <Jumbotron>
      <BoldText>{'r-world - COUNTRY CLUB'}</BoldText>
      </Jumbotron>
      <ListContainer>
        {chatrooms.map(chatroom => (
          <ChatroomContainer key={chatroom._id}>
            <Item onClick={() => handleSelectChatroom(chatroom)}>
              {chatroom.name}
            </Item>
            {(chatroom.hosts.includes(userId) || userRoles.includes('GM')) && (
              <Item onClick={() => deleteChatroom(chatroom._id)}>
                Delete
              </Item>
            )}
          </ChatroomContainer>
        ))}
      </ListContainer>
      <Menu>
        <MenuItem onClick={() => navigate('/editor-feed')}>Feed - Soon</MenuItem>
        <MenuItem onClick={() => setModalVisible(true)}>Start Room</MenuItem>
      </Menu>
      <AuthContainer>
        <UserStatus>
        <BoldTextSmall>Welcome, {username}</BoldTextSmall>
          {isAuthenticated && <div onClick={handleLogout} style={{ color: '#FFF'}}>Logout</div>}
        </UserStatus>
        {!isAuthenticated && (
          <Menu>
            <MenuItem onClick={() => navigate('/login')}>Login</MenuItem>
            <MenuItem onClick={() => navigate('/register')}>Sign Up</MenuItem>
          </Menu>
        )}
      </AuthContainer>
      {modalVisible && (
        <Modal>
          <ModalContent>
            <Input
              placeholder="Enter chatroom name"
              value={chatroomName}
              onChange={e => setChatroomName(e.target.value)}
            />
            <ModalMenu>
              <ModalMenuItem onClick={() => setModalVisible(false)}>Cancel</ModalMenuItem>
              <ModalMenuItem onClick={createChatroom}>Start</ModalMenuItem>
            </ModalMenu>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default ChatroomsPage;