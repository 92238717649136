import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebaseInit';
import { useAuth } from './AuthContext';  // Make sure you have this hook from your context setup
import ThemeContext from './ThemeContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  max-width: 940px;  // Maintain the maximum width as 940px
  margin: 0 auto;  // Center the container horizontally
  width: 100%;  // Ensure it fills the space up to the maximum width
  align-items: center;  // Center content horizontally within the container
  justify-content: center;  // Center content vertically within the container
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;  // Set to flex to control the layout more effectively
  flex-direction: column;  // Stack children vertically
  align-items: center;
  width: 300px;
`;

const Input = styled.input`
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;  // Removes the border
  outline: none;  // Removes the outline on focus
  font-size: ${({ theme }) => theme.text.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.mediumText.fontWeight};
`;

const ForgotPasswordText = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => theme.text.fontFamily};
  cursor: pointer;
  margin-top: 25px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const MenuItem = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.mediumText.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.mediumText.fontFamily};
  color: #000;  // Color of the text
  cursor: pointer;
  background-color: transparent;  // Remove any background color
  border: none;  // Remove borders
  border-radius: 0;  // Remove border radius

  // Remove hover effect
  &:hover {
    background-color: transparent;
  }

  // Focus styles for accessibility, no visual change when clicked
  &:focus {
    outline: none;
  }
`;


const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();
  const { user, loading } = useAuth();  // Use context to manage authentication state

  useEffect(() => {
    if (!loading && user) {
      console.log("User is already signed in, redirecting...");
      navigate('/chatrooms');  // Redirect when user is logged in
    }
  }, [user, loading, navigate]);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful");
      // Navigation will be handled by useEffect based on auth state
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Login Error: ' + error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('Check your email for password reset instructions');
      setIsForgotPassword(false);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Error: ' + error.message);
    }
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    setPassword(''); // Clear password when toggling
  };

  const theme = useContext(ThemeContext);
  return (
    <Container>
      <InputContainer>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!isForgotPassword && (
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
      </InputContainer>
      <Menu>
        <MenuItem onClick={() => navigate('/chatrooms')}>
          Close
        </MenuItem>
        {isForgotPassword ? (
          <MenuItem onClick={handleForgotPassword}>
            Reset Password
          </MenuItem>
        ) : (
          <MenuItem onClick={handleLogin}>
            Login
          </MenuItem>
        )}
      </Menu>
      <ForgotPasswordText onClick={toggleForgotPassword}>
        {isForgotPassword ? 'Back to Login' : 'Forgot Password?'}
      </ForgotPasswordText>
    </Container>
  );
};

export default LoginScreen;