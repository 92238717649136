import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const SupportMsg = styled.p`
  font-size: 16px;
  color: #666;
  text-align: center;
  max-width: 600px;
`;

const Support = () => {
  return (
    <Container>
      <Title>Support</Title>
      <SupportMsg>
        For support, send a message with your issue in a chatroom labelled 'Support' or email rwrld@djinnsandangels.com 
      </SupportMsg>
    </Container>
  );
};

export default Support;