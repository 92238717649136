import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThemeContext from './ThemeContext';
import InteractionComponent from './InteractionComponent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from './AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  margin-top: 15px;
  padding: 0 30px;
  padding-bottom: 5px;
  flex-direction: row;
  justify-content: center;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const UsernameContainer = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
`;

const APBarContainer = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const APBar = styled.div`
  height: 100%;
  background: linear-gradient(
    to right,
    #FF1010, #FF5010, #FF9F10, #FFFA52,
    #F1FF4A, #A7FF31, #09F4F4, #141FF8,
    #210AF9, #A514F8, #CF0DDE, #EA1080
  );
`;

const WhiteSlider = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const BoldText = styled.div`
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
  font-size: ${({ theme }) => theme.boldText.fontSize};
  padding-left: 10px;
`;

const FeedContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
`;

const PostContainer = styled.div`
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
`;

const EditorOutputBlock = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
`;

const TextBlock = styled.div`
  padding: 10px 30px;
  width: 100%;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-size: ${({ theme }) => theme.text.fontSize};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  min-height: 100px;
`;

const MoodboardPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #888;
`;


const EditorFeed = () => {
  const [feedItems, setFeedItems] = useState([]);
  const [imageAspectRatios, setImageAspectRatios] = useState({});
  const [showMoreFlags, setShowMoreFlags] = useState({});
  const [currentPost, setCurrentPost] = useState(null);
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { user } = useAuth();
  const feedContainerRef = useRef(null);

  useEffect(() => {
    fetchFeedItems();
  }, []);

  const fetchFeedItems = async () => {
    try {
      const [textEditorResponse, moodboardResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/text-editor-posts`),
        axios.get(`${API_BASE_URL}/moodboard-posts`),
      ]);

      const textEditorPosts = textEditorResponse.data.map(post => ({
        ...post,
        type: 'TextEditorPost',
      }));

      const moodboardPosts = moodboardResponse.data.map(post => ({
        ...post,
        type: 'MoodboardPost',
      }));
      
      const combinedFeed = [...textEditorPosts, ...moodboardPosts].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setFeedItems(combinedFeed);
    } catch (error) {
      console.error('Error fetching feed items:', error);
    }
  };

  const refreshPost = async (postId) => {
    try {
      const [textEditorResponse, moodboardResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/text-editor-posts`),
        axios.get(`${API_BASE_URL}/moodboard-posts`),
      ]);
  
      const textEditorPosts = textEditorResponse.data.map(post => ({
        ...post,
        type: 'TextEditorPost',
      }));
  
      const moodboardPosts = moodboardResponse.data.map(post => ({
        ...post,
        type: 'MoodboardPost',
      }));
  
      const updatedFeed = [...textEditorPosts, ...moodboardPosts].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      
      setFeedItems(updatedFeed);
      const updatedPost = updatedFeed.find(post => post._id === postId);
      setCurrentPost(updatedPost);
    } catch (error) {
      console.error('Error refreshing post:', error);
    }
  };

  const handleImageLoad = useCallback((event, id) => {
    const { naturalWidth, naturalHeight } = event.target;
    const aspectRatio = naturalWidth / naturalHeight;
    setImageAspectRatios(prevRatios => ({ ...prevRatios, [id]: aspectRatio }));
  }, []);

  const handleScroll = () => {
    if (feedContainerRef.current) {
      const scrollTop = feedContainerRef.current.scrollTop;
      const index = Math.round(scrollTop / window.innerHeight);
      if (feedItems[index] && feedItems[index] !== currentPost) {
        setCurrentPost(feedItems[index]);
      }
    }
  };

  const renderTextEditorPost = (item) => {
    const apPercentage = Math.min((item.ap / item.apCapacity) * 100, 100);

    const sortedContentItems = item.contentItems.sort((a, b) => a.editorOrder - b.editorOrder);

    return (
      <PostContainer>
        <ContentContainer>
          <Header>
            <UsernameContainer>
              <APBarContainer>
                <APBar />
                <WhiteSlider style={{ width: `${100 - apPercentage}%` }} />
                <TextContainer>
                  <BoldText>{item.username}</BoldText>
                </TextContainer>
              </APBarContainer>
            </UsernameContainer>
          </Header>
          <EditorOutputBlock>
            {sortedContentItems.map((contentItem) => {
              if (contentItem.type === 'text') {
                return (
                  <TextBlock key={contentItem._id}>
                    <Text>{contentItem.content.text}</Text>
                  </TextBlock>
                );
              } else if (contentItem.type === 'image') {
                return (
                  <Image
                    key={contentItem._id}
                    src={contentItem.content.uri}
                    style={{ aspectRatio: imageAspectRatios[contentItem._id] || 1 }}
                    onLoad={(event) => handleImageLoad(event, contentItem._id)}
                    alt=""
                  />
                );
              }
              return null;
            })}
          </EditorOutputBlock>
        </ContentContainer>
      </PostContainer>
    );
  };

  const renderMoodboardPost = (item) => {
    return (
      <PostContainer>
        <ContentContainer>
          <MoodboardPlaceholder>
            Moodboard Post
          </MoodboardPlaceholder>
        </ContentContainer>
      </PostContainer>
    );
  };

  return (
    <Container>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </BackButton>
        <UsernameContainer>
          <BoldText>Editor Feed</BoldText>
        </UsernameContainer>
      </Header>
      <FeedContainer ref={feedContainerRef} onScroll={handleScroll}>
        {feedItems.map((item) => (
            <React.Fragment key={item._id}>
            {item.type === 'TextEditorPost' ? renderTextEditorPost(item) : renderMoodboardPost(item)}
            </React.Fragment>
        ))}
        </FeedContainer>
      
      {currentPost && (
        <InteractionComponent 
          post={currentPost} 
          showReadMore={showMoreFlags[currentPost._id]} 
          refreshPost={refreshPost} 
        />
      )}
      
    </Container>
  );
};

export default EditorFeed;