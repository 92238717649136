// styles/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif; // Make sure Poppins is linked in your HTML or imported in your CSS
    font-weight: 400; // Regular weight for Poppins
  }
`;

export default GlobalStyle;