import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { auth } from './firebaseInit';
import { useAuth } from './AuthContext'; // Adjust the path as necessary
import ReactPlayer from 'react-player';
import ThemeContext from './ThemeContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OverlayComponent from './OverlayComponent';
import ChatroomInteractions from './ChatroomInteractions';
import { useMediaQuery } from 'react-responsive';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Container = styled.div`
  display: flex;
  //flex-direction: column;
  //height: 100vh;
  //background-color: #fff;
  //max-width: 860px;
  //margin: 0 auto;
  //width: 100%;
  //overflow: hidden;  // This prevents scrolling outside the bounds

  @media (min-width: 768px) {
    flex-direction: row;
    //width: 100%;
    
  }
`;

const LargePlayerContainer = styled.div`


  //background-color: red;
  width: 100%;
  margin: auto;

  
`;

const ChatContainer = styled.div`
display: flex;
flex-direction: column;
max-width: 860px;
height: 100vh;
width: 100%;
margin: 0 auto;
overflow: hidden;
background-color: #fff;
`;

const Header = styled.div`
  display: flex; 
  margin-top: 15px;
  padding: 0 30px;
  padding-bottom: 5px;
  flex-direction: row;
  justify-content: center;
  //background-color: pink;
  //position: relative;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px; // Adjust spacing as needed
`;

const UsernameContainer = styled.div`
  display: flex;
  //flex-grow: 1;
  width: 70%;
  align-items: center;
  //background-color: lightblue; // Debugging purpose
`;

const APBarContainer = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
  //border-radius: 5px;
  //border-bottom: 1px solid;
  overflow: hidden;
`;

const APBar = styled.div`

  height: 100%;
  background: linear-gradient(
    to right,
    #FF1010 0%,
    #FF5010 10%,
    #FF5010 10%,
    #FF9F10 15%,
    #FF9F10 15%,
    #FFFA52 25%,
    #FFFA52 25%,
    #F1FF4A 27%,
    #F1FF4A 27%,
    #A7FF31 35%,
    #A7FF31 35%,
    #09F4F4 45%,
    #09F4F4 45%,
    #141FF8 58%,
    #141FF8 58%,
    #210AF9 60%,
    #210AF9 60%,
    #A514F8 70%,
    #A514F8 70%,
    #CF0DDE 80%,
    #CF0DDE 80%,
    #EA1080 100%
  );
`;

const WhiteSlider = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
`;

const TextContainer = styled.div`
  position: absolute;
    top: 0;
    left: 0;
  //justify-content: center;
  //position: absolute;
  //padding-top: 4px;
  //background-color: pink; // Debugging purpose
`;

const BoldText = styled.div`
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
  font-size: ${({ theme }) => theme.boldText.fontSize};
  padding-left: 10px;
`;

const BadgeContainer = styled.div` 
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LevelContainer = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //margin-left: 10px;
  //font-family: ${({ theme }) => theme.boldText.fontFamily};
  //font-weight: ${({ theme }) => theme.boldText.fontWeight};
  //font-size: ${({ theme }) => theme.boldText.fontSize};
  //color: ${({ theme }) => theme.boldText.color};
`;


const BadgePedestal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const BadgeImage = styled.img`
  width: 35px;
  height: 35px;
  resize-mode: contain;
`;

const BadgeTrailContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0px;
`;

const BadgeTrailImage = styled.img`
  width: 25px;
  height: 25px;
  resize-mode: contain;
`;

const Player = styled.div`
    //padding-bottom: ${props => props.$youtubeLink ? '5px' : '0'};
`;

const MessageArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const ImageContainer = styled.div`
  width: 20%;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 20px;
  //border-radius: 12px;
  border-bottom: 1px solid;
  align-items: center;
  align-self: ${props => props.$isOwn ? 'flex-end' : 'flex-start'};
`;

const MessageImage = styled.img`
  width: 100%;

  //margin-bottom: 4px;
  aspect-ratio: ${props => props.$aspectRatio || 1};
`;

const MsgHeader = styled.div`
  display: flex;
  flex-direction: row;
  
`;

const MsgBadgePedestal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
`;

const MsgBadgeImage = styled.img`
  width: 20px;
  height: 20px;
  resize-mode: contain;
`;

const MsgTrailContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MsgTrailImage = styled.img`
  width: 18px;
  height: 18px;
  resize-mode: contain;
`;
const TextBox = styled.div`
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.text.fontWeight};
  font-size: 14px;
  border-radius: 14px;
  padding: 10px;
  margin: 4px 15px;
  background-color: ${props => props.$isOwn ? '#DCF8C6' : 'white'};
  max-width: 70%;
  align-self: ${props => props.$isOwn ? 'flex-end' : 'flex-start'};
`;

const Sender = styled.div`
font-family: ${({ theme }) => theme.boldText.fontFamily};
font-weight: ${({ theme }) => theme.boldText.fontWeight};
font-size: 14px;
  cursor: pointer;
`;

const Message = styled.div`
  //margin-top: 5px;
`;

const SystemMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  max-width: 80%;
  justify-content: center; /* Center content within the container */
`;

const SystemMessageText = styled.div`
  font-style: italic;
  color: red;
  text-align: center;
`;

const SystemBadgeImage = styled.img`
  width: 20px;
  height: 20px;
  resize-mode: contain;
  margin-left: 5px;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0 5px;
`;

const Tools = styled.div`
  display: flex;
  padding: 0 20px;
  font-size: 14px;
`;

const ToolItem = styled.div`
  padding: 0 5px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;  // Ensure vertical alignment is centered
  justify-content: space-around;
  margin: 0 12px 15px;
  width: 100%;  // Take full width to utilize space
`;


const Input = styled.input`
  flex: 1;  // Input takes 90% of the space
  height: 15px;
  border: 1px solid;
  padding: 10px;
  border-radius: 20px;  // Full rounded corners
`;

const SendButton = styled.span`  // Changed from div to span for inline element 
  font-size: 16px;
  color: #000;  // Give it a distinct color or keep it black
  cursor: pointer;  // Keeps the cursor as pointer to indicate it's clickable
  display: flex;
  align-items: center;  // Center the text vertically
  justify-content: center;  // Center the text horizontally
  padding: 10px 14px;  // Adequate padding for clickable area

  &:hover {
    text-decoration: none;  // Optional: underline on hover to indicate it's clickable
  }

  &:focus {
    outline: none;  // Remove focus outline
  }
`;

const ModalBackground = styled.div`
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalInput = styled.input`
width: 300px;
height: 40px;
margin-bottom: 10px;
padding-top: 10px;
padding-bottom: 10px;
border: none;  // Removes the border
outline: none;  // Removes the outline on focus
font-size: ${({ theme }) => theme.text.fontSize}; // Applying theme font size
font-family: ${({ theme }) => theme.text.fontFamily};
font-weight: ${({ theme }) => theme.mediumText.fontWeight};
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const MenuItem = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.mediumText.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.mediumText.fontFamily};
  color: #000;  // Color of the text
  cursor: pointer;
  background-color: transparent;  // Remove any background color
  border: none;  // Remove borders
  border-radius: 0;  // Remove border radius

  // Remove hover effect
  &:hover {
    background-color: transparent;
  }

  // Focus styles for accessibility, no visual change when clicked
  &:focus {
    outline: none;
  }
`;

const FullScreenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 35px;
  right: 60px;
  //padding: 35px 35px;
  cursor: pointer;
`;

const UnauthenticatedContainer = styled.div`
  display: flex;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UnauthenticatedText = styled.p`
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-size: ${({ theme }) => theme.boldText.fontSize};
  margin-bottom: 20px;
`;

const ChatroomScreen = () => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [username, setUsername] = useState('');
  const [isHost, setIsHost] = useState(false);
  const [hostDetails, setHostDetails] = useState([]);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const playerRef = useRef(null);
  const [expectingUpdateFromServer, setExpectingUpdateFromServer] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [userId, setUserId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [imageAspectRatios, setImageAspectRatios] = useState(new Map());
  const [youtubeInput, setYoutubeInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const [isImageViewVisible, setImageViewVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const messageAreaRef = useRef(null);
  const theme = useContext(ThemeContext);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayType, setOverlayType] = useState(null);
  const [target, setTarget] = useState(null); // State for target
  const [chatroomData, setChatroomData] = useState(null);
  const [chatroomAP, setChatroomAP] = useState(0); // State for chatroom AP
  const [chatroomAPCapacity, setChatroomAPCapacity] = useState(0); // State for chatroom AP capacity
  const [chatroomLevel, setChatroomLevel] = useState(1);
  const [highestValueBadge, setHighestValueBadge] = useState(null); // State for highest value badge
  const [mostRecentBadges, setMostRecentBadges] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { user, loading, getToken } = useAuth();

  // Extract video ID function
  const extractVideoID = useCallback((url) => {
    const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  }, []);

  const videoId = extractVideoID(youtubeLink);

  const location = useLocation();
  const navigate = useNavigate();
  const { chatroomId } = useParams();
  const { chatroomName } = location.state || {};

  const isDesktop = useMediaQuery({ minWidth: 768 });


  const updatePlayerState = useCallback((playState, position) => {
    if (playerRef.current && playerRef.current.seekTo) {
      playerRef.current.seekTo(position, true);
      setIsPlaying(playState);
    }
  }, []);

  const handleStartWatchParty = useCallback(() => {
    const videoId = extractVideoID(youtubeInput);
    if (socket && videoId) {
        setShowModal(false);
        socket.emit('startWatchParty', {
            roomId: chatroomId,
            videoId: videoId,
            currentPosition: 0,
        });
        setYoutubeLink(`https://www.youtube.com/watch?v=${videoId}`);
    } else {
        console.error('[Client] Invalid socket or video ID', socket, videoId);  // Corrected variable name here
    }
}, [youtubeInput, socket, chatroomId, extractVideoID]);


  const handleStopWatchParty = useCallback(() => {
    if (socket) {
      getToken().then(token => {
        axios.delete(`${API_BASE_URL}/chatrooms/${chatroomId}/watch-party`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            console.log('[Client] Watch party stopped successfully');
            setIsPlaying(false);
            setYoutubeLink('');
            setCurrentPosition(0);
            socket.emit('stopWatchParty', { roomId: chatroomId });
          })
          .catch(error => console.error('Error stopping watch party:', error));
      });
    }
  }, [chatroomId, socket]);

  const handleSync = useCallback(() => {
    if (youtubeLink && socket) {
        console.log('Emitting sync request for room:', chatroomId);
        socket.emit('requestSync', { roomId: chatroomId }, (response) => {
            console.log('Sync response:', response);
        });
    } else {
        console.log('Sync request not sent. Either no active watch party or socket is not connected.');
        if (!youtubeLink) {
            alert('There are no active watch parties.');
        }
    }
}, [youtubeLink, socket, chatroomId]);

const handlePlayerStateChange = useCallback((state) => {
  console.log('[Client] Player state changed:', state);
  if (isHost) {
    if (state === 'playing' || state === 'paused') {
      const currentTime = playerRef.current.getCurrentTime();
      setCurrentPosition(currentTime);

      // Only emit the playPauseVideo event if the playState has changed
      if ((state === 'playing' && !isPlaying) || (state === 'paused' && isPlaying)) {
        setIsPlaying(state === 'playing');
        getToken().then(token => {
          console.log('[Client] Emitting playPauseVideo event');
          socket.emit('playPauseVideo', {
            roomId: chatroomId,
            playState: state,
            currentPosition: currentTime,
            timestamp: Date.now(),
            token: token,
          });
        });
      }
    }
  } else {
    // For non-hosts, update the local player state without emitting to the server
    if (state === 'playing' || state === 'paused') {
      setIsPlaying(state === 'playing');
    }
  }
}, [chatroomId, socket, isPlaying, setIsPlaying, setCurrentPosition, isHost]);
  

  const handleSeekVideo = useCallback((position) => {
    console.log('[Client] handleSeekVideo called with position:', position);
    if (playerRef.current && playerRef.current.seekTo) {
      console.log('[Client] Seeking to position:', position);
      playerRef.current.seekTo(position, true);
      setCurrentPosition(position);

      const timestamp = Date.now();
      getToken().then(token => {
        console.log('[Client] Emitting seekVideo event');
        socket.emit('seekVideo', {
          roomId: chatroomId,
          currentPosition: position,
          timestamp: timestamp,
          token: token,
        });
      });
    }
  }, [chatroomId, socket, setCurrentPosition]);

  const handleWatchPartyStateUpdate = useCallback((data) => {
    console.log('[Client] Received watchPartyStateUpdated:', data);
    const { playState, currentPosition, videoId } = data;
  
    // Only update the player state if it has changed
    if (playState !== isPlaying) {
      setIsPlaying(playState === 'playing');
      setCurrentPosition(currentPosition);
      setYoutubeLink(`https://www.youtube.com/watch?v=${videoId}`);
      setTriggerRerender(!triggerRerender);
      updatePlayerState(playState === 'playing', currentPosition);
    }
  }, [isPlaying, updatePlayerState, triggerRerender]);

  const getHighestValueBadge = (givers) => {
    if (!givers || givers.length === 0) return null;
  
    let highestChromaBadge = null;
    let highestChromaValue = 0;
  
    givers.forEach(giver => {
      if (giver && giver.badges) {
        giver.badges.forEach(badge => {
          if (badge.badgeId.currency === 'Chroma' && badge.badgeId.chromaCost > highestChromaValue) {
            highestChromaValue = badge.badgeId.chromaCost;
            highestChromaBadge = badge;
          }
        });
      }
    });
  
    if (highestChromaBadge) return highestChromaBadge;
  
    let highestApBadge = null;
    let highestApValue = 0;
  
    givers.forEach(giver => {
      if (giver && giver.badges) {
        giver.badges.forEach(badge => {
          if (badge.badgeId.currency === 'AP') {
            const badgeValue = badge.badgeId.costLevels.reduce((max, level) => Math.max(max, level.percentage), 0);
            if (badgeValue > highestApValue) {
              highestApValue = badgeValue;
              highestApBadge = badge;
            }
          }
        });
      }
    });
  
    return highestApBadge;
  };
  
  const getMostRecentBadges = (givers) => {
    let recentBadges = [];
  
    givers.forEach(giver => {
      if (giver && giver.badges) {
        recentBadges = recentBadges.concat(giver.badges);
      }
    });
  
    return recentBadges.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3);
  };

  const openOverlay = (type) => {
    setOverlayType(type);
    setOverlayVisible(true);
  };
  
  const closeOverlay = () => {
    setOverlayVisible(false);
    setOverlayType(null);
  };
  
  const eject = useCallback((message) => {
    navigate('/chatrooms');
    setTimeout(() => {
      alert(message);
    }, 500);
  }, [navigate]);

  const handleSocketError = useCallback((error) => {
    const errorMessage = typeof error === 'string' ? error : error.message;
    console.error('Socket error:', errorMessage);
    
    if (errorMessage === 'You cannot join this chatroom') {
      console.log('User does not have permission to join this chatroom. Redirecting...');
      navigate('/chatrooms'); // Navigate to the Home screen or any other screen
      setTimeout(() => {
        alert('Access Denied: You got redirected because you do not have permission to access this chatroom.');
      }, 500);
    } else {
      console.log('Unhandled socket error:', errorMessage);
    }
  }, [navigate]);

  const fetchChatroomDetails = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/chatrooms/${chatroomId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to fetch chatroom details:', errorText);
        if (errorText === 'You cannot access this chatroom because you have blocked a host') {
          eject(errorText);
          return;
        }
        throw new Error(`Failed to fetch chatroom details: ${errorText}`);
      }
      const data = await response.json();
    setChatroomData(data);
    setHostDetails(data.hosts);
    setChatroomAP(data.ap);
    setChatroomAPCapacity(data.apCapacity);
    setChatroomLevel(data.level);
    setIsHost(data.isHost);
    setHighestValueBadge(getHighestValueBadge(data.givers));
    setMostRecentBadges(getMostRecentBadges(data.givers));
    // Set the target with the username of the first host
    const firstHostUsername = data.hosts.length > 0 ? data.hosts[0].username : '';
    setTarget({ id: chatroomId, contentType: 'Chatroom', username: firstHostUsername });
  } catch (error) {
    console.error('Error fetching chatroom details:', error);
  }
  };

  const fetchMessages = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/messages/${chatroomId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch messages');
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const initChatroomAndSocket = useCallback(async () => {
    if (!user) {
      console.error('User not authenticated');
      setIsAuthenticated(false);
      return;
    }
    setIsAuthenticated(true);

    const token = await getToken();
    setUserId(user.uid);
    console.log('Fetched user ID:', user.uid);

    try {
      const response = await fetch(`${API_BASE_URL}/users/byUid/${user.uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch user details');
      const userData = await response.json();
      setUsername(userData.username);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setUsername('Me');
    }

    await fetchChatroomDetails(token);
    await fetchMessages(token);

    if (!socket) {
      const newSocket = io(API_BASE_URL, {
        withCredentials: true,
        transports: ['websocket', 'polling'],
        auth: { token }
      });

      newSocket.on('connect_error', (error) => {
        console.error('Connection Error:', error);
      });

      newSocket.on('error', (error) => {
        const errorMessage = typeof error === 'string' ? error : error.message;
        console.error('Socket error:', errorMessage);
        if (errorMessage === 'You cannot join this chatroom') {
          eject(errorMessage);
        }
      });

      newSocket.on('watchPartyStateUpdated', handleWatchPartyStateUpdate);
      newSocket.on('watchPartyStopped', () => {
        setIsPlaying(false);
        setYoutubeLink('');
        setCurrentPosition(0);
        console.log('[Client] Watch party has been stopped');
      });

      newSocket.emit('joinRoom', { roomId: chatroomId });

      newSocket.on('newMessage', (newMessage) => {
        const isSystemMsg = newMessage.username === 'System';
        setMessages(prevMessages => [
          ...prevMessages,
          {
            ...newMessage,
            userId: isSystemMsg ? 'system' : newMessage.userId, // Adjust userId field
            messageId: newMessage.messageId, // Include messageId here
            isSystemMessage: isSystemMsg // Add a flag for system messages
          }
        ]);
      });
      
      
      

      // Add the badgePurchaseUpdate listener here
      newSocket.on('badgePurchaseUpdate', handleBadgeUpdate);

      setSocket(newSocket);

      return () => {
        newSocket.off('watchPartyStateUpdated', handleWatchPartyStateUpdate);
        newSocket.off('newMessage');
        newSocket.off('error');
        newSocket.off('badgePurchaseUpdate', handleBadgeUpdate);
        newSocket.emit('leaveRoom', { roomId: chatroomId });
        newSocket.disconnect();
      };
    }
  }, [user, chatroomId, socket, handleSocketError]);
  
  useEffect(() => {
    if (!loading && user) {
      initChatroomAndSocket();
    }
  }, [user, loading]); 


  useEffect(() => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [messages]);

  const handleImageSelection = event => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
  
      // Immediately attempt to upload the image upon selection
      axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        if (response.data && response.data.url) {
          setSelectedImage(response.data.url);  // Store the URL returned by the backend
        } else {
          console.error('Error uploading image:', response.data);
        }
      }).catch(error => {
        console.error('Error uploading image:', error);
      });
    }
  };
  
  const sendMessage = useCallback(() => {
    if (!message.trim()) {
      // If there's no text, show an alert, regardless of whether an image is selected
      alert("Messages must include text");
      return;
    }
  
    if (!isSending && socket) {
      setIsSending(true);
      const messageId = uuidv4();
      const messageData = {
        messageId,
        text: message.trim(),
        roomId: chatroomId,
        userId: userId,
        username: username,
        imageUrl: selectedImage,
      };
  
      // Emit the message with the image URL (if any)
      socket.emit('chatMessage', messageData, (error, success) => {
        if (error) {
          console.error('Error sending message:', error);
        } else {
          console.log('Message sent successfully:', success);
        }
        setIsSending(false);
        setMessage('');
        setSelectedImage(null);  // Clear the image after sending
      });
    }
  }, [message, selectedImage, isSending, socket, chatroomId, userId]);

  
  const processBadgeUpdate = async (update) => {
    console.log('Processing badge update:', update);
    const token = await getToken();
    if (update.contentType === 'Chatroom') {
      await fetchChatroomDetails(token);
    } else if (update.contentType === 'Message') {
      await fetchMessages(token);
    }
  };

  const handleBadgeUpdate = useCallback((update) => {
    console.log('Received badgePurchaseUpdate event:', update);
    processBadgeUpdate(update);
  }, []);


  const updateAspectRatio = useCallback((url, ratio) => {
    setImageAspectRatios(prevMap => {
      const newMap = new Map(prevMap);
      newMap.set(url, ratio);
      return newMap;
    });
  }, []);
    const handleImageLoad = useCallback((imageUrl) => {
    const img = new Image();
    img.onload = () => {
    const aspectRatio = img.width / img.height;
    updateAspectRatio(imageUrl, aspectRatio);
    };
    img.src = imageUrl;
    }, [updateAspectRatio]);
    const handleImagePress = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageViewVisible(true);
    };

    const handleUsernameTap = (messageId, username) => {
      setTarget({ id: messageId, contentType: 'Message', username });
      console.log(`Target set to message: ${messageId}, contentType: Message, username: ${username}`);
    };

    useEffect(() => {
      console.log("Username set to:", username);
    }, [username]);

    const apPercentage = Math.min((chatroomAP / chatroomAPCapacity) * 100, 100);

    return (
        <Container>
        {isAuthenticated ? (
          <>
        {isDesktop && videoId && (
      <LargePlayerContainer $youtubeLink={youtubeLink}>
        <ReactPlayer
          key={triggerRerender}
          ref={playerRef}
          height="60vh"
          width="100%"
          style={{ margin: 'auto' }}
          url={`https://www.youtube.com/watch?v=${videoId}`}
          playing={isPlaying}
          controls={true}
          onProgress={({ playedSeconds }) => {
            setCurrentPosition(playedSeconds);
          }}
          onPlay={() => handlePlayerStateChange('playing')}
          onPause={() => handlePlayerStateChange('paused')}
          onEnded={() => handlePlayerStateChange('ended')}
          onReady={() => {
            updatePlayerState(isPlaying, currentPosition);
          }}
        />
      </LargePlayerContainer>
    )}

        <ChatContainer>
          <Header>
          <BackButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </BackButton>
          <UsernameContainer>
              <APBarContainer>
                <APBar />
                <WhiteSlider style={{ width: `${100 - apPercentage}%` }} />
                <TextContainer>
                  <BoldText>{chatroomName}</BoldText>
                </TextContainer>
              </APBarContainer>
        </UsernameContainer>
          
          
        <BadgeContainer>
        <LevelContainer>
            Lv. {chatroomLevel}
          </LevelContainer>
          <BadgePedestal onClick={() => { setTarget({ id: chatroomId, contentType: 'Chatroom', username: '' }); openOverlay('LEADERBOARD'); }}>
            {highestValueBadge && (
              <BadgeImage src={highestValueBadge.badgeId.imageUrl} />
            )}
          </BadgePedestal>
          <BadgeTrailContainer onClick={() => { setTarget({ id: chatroomId, contentType: 'Chatroom', username: '' }); openOverlay('LEADERBOARD'); }}>
            {mostRecentBadges.map(badge => (
              <BadgeTrailImage key={badge._id} src={badge.badgeId.imageUrl} />
            ))}
          </BadgeTrailContainer>
        </BadgeContainer>
          </Header>
          {!isDesktop && videoId && (
        <Player $youtubeLink={youtubeLink}>
          <ReactPlayer
            key={triggerRerender}
            ref={playerRef}
            height="40vh"
            width="100%"
            style={{ margin: 'auto' }}
            url={`https://www.youtube.com/watch?v=${videoId}`}
            playing={isPlaying}
            controls={true}
            onProgress={({ playedSeconds }) => {
              setCurrentPosition(playedSeconds);
            }}
            onPlay={() => handlePlayerStateChange('playing')}
            onPause={() => handlePlayerStateChange('paused')}
            onEnded={() => handlePlayerStateChange('ended')}
            onReady={() => {
              updatePlayerState(isPlaying, currentPosition);
            }}
          />
        </Player>
      )}
          <MessageArea ref={messageAreaRef}>
    {messages.map((item, index) => {
      if (item.username === 'System') {
        return (
          <SystemMessageContainer key={index}>
            {item.imageUrl && (
              <SystemBadgeImage src={item.imageUrl} />
            )}
            <SystemMessageText>{item.text}</SystemMessageText>
          </SystemMessageContainer>
        );
      }

      const isOwnMessage = item.username === username;
      const highestValueBadge = item.givers ? getHighestValueBadge(item.givers) : null;
      const mostRecentBadges = item.givers ? getMostRecentBadges(item.givers) : [];
      return (
        <MessageContainer key={index}>
          {item.imageUrl && (
            <ImageContainer $isOwn={isOwnMessage}>
              <div onClick={() => handleImagePress(item.imageUrl)}>
                <MessageImage
                  src={item.imageUrl}
                  $aspectRatio={imageAspectRatios.get(item.imageUrl) || 1}
                  onLoad={() => handleImageLoad(item.imageUrl)}
                />
              </div>
            </ImageContainer>
          )}
          <TextBox $isOwn={isOwnMessage}>
            <MsgHeader>
              <Sender onClick={() => handleUsernameTap(item.messageId, item.username)}>
                {item.username || "Anonymous"}
              </Sender>
              
              <MsgBadgePedestal onClick={() => { setTarget({ id: item._id, contentType: 'Message', username: item.username }); openOverlay('LEADERBOARD'); }}>
                {highestValueBadge && (
                  <MsgBadgeImage src={highestValueBadge.badgeId.imageUrl} />
                )}
              </MsgBadgePedestal>
              <MsgTrailContainer onClick={() => { setTarget({ id: item._id, contentType: 'Message', username: item.username }); openOverlay('LEADERBOARD'); }}>
                {mostRecentBadges.map(badge => (
                  <MsgTrailImage key={badge._id} src={badge.badgeId.imageUrl} />
                ))}
              </MsgTrailContainer>
              
            </MsgHeader>
            <Message>{item.text}</Message>
          </TextBox>
          
        </MessageContainer>
      );
    })}
  </MessageArea>

            {selectedImage && (
                <ThumbnailContainer>
                    <Thumbnail src={selectedImage} />
                    <div onClick={() => setSelectedImage(null)}>Close</div>
                </ThumbnailContainer>
                )}
          {/*
          <Toolbar>
            <Tools>
                <input type="file" accept="image/*" onChange={handleImageSelection} style={{ display: 'none' }} ref={fileInputRef} />
                <ToolItem onClick={() => fileInputRef.current && fileInputRef.current.click()}>Add Image</ToolItem>
            </Tools>
            <Tools>
              <ToolItem onClick={() => isHost ? setShowModal(true) : alert('Only the host can start the watch party.')}>
                Play
              </ToolItem>
              <ToolItem onClick={() => isHost ? handleStopWatchParty() : alert('Only the host can stop the watch party.')}>
                Stop
              </ToolItem>
              <ToolItem onClick={() => {
                console.log('Sync button clicked');
                handleSync();
                }}>Sync
                </ToolItem>
            </Tools>
          </Toolbar>
          <InputContainer>
            <Input
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
            <SendButton onClick={sendMessage}>
              Send
            </SendButton>
          </InputContainer>
              */}
          {showModal && (
            <ModalBackground>
              <ModalContent>
                <ModalInput
                  placeholder="Paste YouTube link here"
                  value={youtubeInput}
                  onChange={e => setYoutubeInput(e.target.value)}
                />
                <Menu>
                  <MenuItem onClick={() => setShowModal(false)}>Cancel</MenuItem>
                  <MenuItem onClick={handleStartWatchParty}>Start</MenuItem>
                </Menu>
              </ModalContent>
            </ModalBackground>
          )}
          {isImageViewVisible && (
            <ModalBackground>
              <ModalContent>
                <CloseButton onClick={() => setImageViewVisible(false)}>
                  Close
                </CloseButton>
                <FullScreenImage src={currentImage} />
              </ModalContent>
            </ModalBackground>
          )}
          <ChatroomInteractions 
        chatroom={{ id: chatroomId }}
        setTarget={setTarget}
        target={target}
        sendMessage={sendMessage}
        inputText={message}
        setInputText={setMessage}
        isHost={isHost}
        setShowModal={setShowModal}
        handleStopWatchParty={handleStopWatchParty}
        youtubeLink={youtubeLink}
        socket={socket}
        chatroomId={chatroomId}
        handleImageSelection={handleImageSelection}
        fetchMessages={fetchMessages}
        fetchChatroomDetails={fetchChatroomDetails}
        hostDetails={hostDetails}
      />
          <OverlayComponent
      visible={overlayVisible}
      type={overlayType}
      data={
          target && target.contentType === 'Chatroom'
            ? { givers: chatroomData?.givers || [] }
            : { givers: messages.find(msg => msg._id === target?.id)?.givers || [] }
        }
        target={target}
      onClose={closeOverlay}
    />
        </ChatContainer>
        </>
     ) : (
      <UnauthenticatedContainer>
        <UnauthenticatedText>
          Please login or sign up to participate.
        </UnauthenticatedText>
        <Menu>
          <MenuItem onClick={() => navigate('/login')}>Login</MenuItem>
          <MenuItem onClick={() => navigate('/register')}>Sign Up</MenuItem>
        </Menu>
      </UnauthenticatedContainer>
    )}
        </Container>
      );
    };    
    export default ChatroomScreen;