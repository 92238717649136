import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseInit';
import axios from 'axios';
import { useAuth } from './AuthContext';
import ThemeContext from './ThemeContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  max-width: 940px;  // Maintain the maximum width as 940px
  margin: 0 auto;  // Center the container horizontally
  width: 100%;  // Ensure it fills the space up to the maximum width
  align-items: center;  // Center content horizontally within the container
  justify-content: center;  // Center content vertically within the container
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;  // Set to flex to control the layout more effectively
  flex-direction: column;  // Stack children vertically
  align-items: center;
`;

const Input = styled.input`
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;  // Removes the border
  outline: none;  // Removes the outline on focus
  font-size: ${({ theme }) => theme.text.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.mediumText.fontWeight};
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const MenuItem = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.mediumText.fontSize}; // Applying theme font size
  font-family: ${({ theme }) => theme.mediumText.fontFamily};
  color: #000;  // Color of the text
  cursor: pointer;
  background-color: transparent;  // Remove any background color
  border: none;  // Remove borders
  border-radius: 0;  // Remove border radius

  // Remove hover effect
  &:hover {
    background-color: transparent;
  }

  // Focus styles for accessibility, no visual change when clicked
  &:focus {
    outline: none;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  max-width: 300px;
`;

const SetUsernameScreen = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user, getToken } = useAuth();

  const validateUsername = (username) => {
    // Remove any leading/trailing whitespace
    username = username.trim();

    if (username.length < 2 || username.length > 25) {
      return 'Username must be between 2 and 25 characters long.';
    }

    if (!/^[a-zA-Z0-9_.-]+$/.test(username)) {
      return 'Username can only contain letters, numbers, and the characters _ . - without spaces';
    }

    return null; // No error
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    setError(validateUsername(newUsername));
  };

  const handleSetUsername = async () => {
    if (!user) { // Change this line
      alert('No user logged in');
      return;
    }

    const validationError = validateUsername(username);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const token = await getToken();
      console.log('Token retrieved:', token);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;
      console.log('Posting to:', apiUrl);
      const response = await axios.post(apiUrl, { username, firebaseUid: user.uid }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Response received:', response.data);
      if (response.status === 201) {
        alert('Username set successfully');
        navigate('/chatrooms');
      } else {
        console.error('Unexpected response status:', response.status);
        alert(`Failed to set username: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error setting username:', error);
      alert(error.response?.data?.message || 'Failed to set username');
    }
  };

  return (
    <Container>
      <InputContainer>
        <Input
          type="text"
          placeholder="Enter your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputContainer>
      <Menu>
        <MenuItem onClick={() => navigate('/chatrooms')}>
          Close
        </MenuItem>
        <MenuItem onClick={handleSetUsername}>
          Done
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default SetUsernameScreen;