import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const TermsLink = styled.a`
  font-size: 18px;
  color: #007BFF;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

function Terms() {
  // Replace this URL with your actual Terms of Service URL
  const termsOfServiceUrl = "https://docs.google.com/document/d/1rfS97eINymLiUMAKKkgp07icHhOPm7fIK1tkY_nV4hM/edit?usp=sharing";

  return (
    <Container>
      <Title>Terms of Service</Title>
      <TermsLink href={termsOfServiceUrl} target="_blank" rel="noopener noreferrer">
        Click here to view our Terms of Service
      </TermsLink>
    </Container>
  );
}

export default Terms;