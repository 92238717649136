// ThemeContext.js
import { createContext } from 'react';

const ThemeContext = createContext({
  text: {
    fontFamily: 'Poppins, sans-serif',  // Assuming Poppins is one of the fonts you included
    fontSize: '16px',
  },
  boldText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700', // Bold weight for Poppins, adjust according to what you included
    fontSize: '18px',
  },
  mediumText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500', // Medium weight for Poppins
    fontSize: '16px',
  },
  // You can define other styles as needed
});

export default ThemeContext;
