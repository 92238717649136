import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Ensure AuthProvider is imported
import GlobalStyle from './GlobalStyle';
import HomeScreen from './HomeScreen';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import ChatroomsPage from './ChatroomsPage';
import ChatroomScreen from './ChatroomScreen';
import SetUsernameScreen from './UsernameScreen';
import EditorFeed from './EditorFeed';
import DeleteAccount from './DeleteAccount';
import Privacy from './Privacy';
import Support from './Support';
import Terms from './Terms';
import ThemeContext from './ThemeContext';
import { ThemeProvider } from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const theme = {
  text: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
  },
  boldText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700',
    fontSize: '18px',
  },
  mediumText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    fontSize: '18px',
  },
};

// Add this block at the top of your file, outside of the App component
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  const originalConsoleLog = console.log;
  console.log = function(...args) {
    if (args[0] === 'CRITICAL:') {
      originalConsoleLog.apply(console, args);
    }
    // Other logs are ignored in production
  };
}

function App() {
  const theme = useContext(ThemeContext);
  // Removed useEffect with Firebase initialization and onAuthStateChanged listener
  return (
    <AuthProvider> {/* This now handles all Firebase Auth initialization and state management */}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/" element={<ChatroomsPage />} />
              <Route path="/orhc" element={<HomeScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route path="/chatrooms" element={<ChatroomsPage />} />
              <Route path="/set-username" element={<SetUsernameScreen />} />
              <Route path="/chatrooms/:chatroomId" element={<ChatroomScreen />} />
              <Route path="/feed" element={<EditorFeed />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/support" element={<Support />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
            </Routes>
          </Elements>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
