import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThemeContext from './ThemeContext';
import OverlayComponent from './OverlayComponent';
import { useAuth } from './AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PersonIcon from '@mui/icons-material/Person';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CircleIcon from '@mui/icons-material/Circle';
import io from 'socket.io-client';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MoodboardDataContainer = styled.div`
  position: absolute;
  top: 68px;
  left: 28px;
  background-color: white;
  z-index: 100;
  padding: 5px;
`;

const AuthorUsername = styled.div`
  color: black;
  font-size: 16px;
  padding-top: 5px;
  font-family: ${({ theme }) => theme.boldText.fontFamily};
`;

const MoodboardAPBarContainer = styled.div`
  height: 12px;
  overflow: hidden;
`;

const APBar = styled.div`
  height: 100%;
  background: linear-gradient(
    to right,
    #FF1010, #FF5010, #FF9F10, #FFFA52,
    #F1FF4A, #A7FF31, #09F4F4, #141FF8,
    #210AF9, #A514F8, #CF0DDE, #EA1080
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const WhiteSlider = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
`;

const PostLevelContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 25px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
`;

const PostLevelText = styled.div`
  font-size: 14px;
`;

const PedestalBadgeContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 25px;
  display: flex;
  z-index: 100;
`;

const BadgePedestal = styled.div`
  justify-content: center;
  padding: 8px;
  border-radius: 30px;
  background-color: white;
`;

const BadgeImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

const BadgeTrailContainer = styled.div`
  position: absolute;
  top: 9.25%;
  right: 5.5%;
  z-index: 100;
`;

const BadgeTrailImage = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`;

const QuickBadgeStore = styled.div`
  position: absolute;
  bottom: 60px;
  left: 30px;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
`;

const BadgeItemsContainer = styled.div`
  flex: 1;
  display: flex;
  height: 20px;
  background-color: rgba(0,0,0,0.0);
`;

const MiniBadgeImage = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 16px;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Dock = styled.div`
  flex: 1;
  height: 45px;
  border: 1px solid black;
  background-color: white;
  display: flex;
  align-items: center;
`;

const APBarContainer = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const DockMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Button = styled.button`
  background-color: white;
  padding: 4px;
  border-radius: 50%;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TextButton = styled.button`
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  border: 2px solid black;
  cursor: pointer;
  font-family: ${({ theme }) => theme.boldText.fontFamily};
  font-weight: ${({ theme }) => theme.boldText.fontWeight};
`;

const ChromaImage = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`;

const NotificationCircle = styled.div`
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: red;
`;

const ToggleContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 30px;
  z-index: 100;
`;

const ReadMoreContainer = styled.div`
  position: absolute;
  bottom: 45px;
  right: 45px;
  z-index: 100;
`;

const InteractionComponent = ({ post, showReadMore, refreshPost }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [badges, setBadges] = useState([]);
  const [badgePrices, setBadgePrices] = useState({});
  const [ap, setAP] = useState(0);
  const [apCapacity, setAPCapacity] = useState(100);
  const [level, setLevel] = useState(1);
  const [authorUsername, setAuthorUsername] = useState('');
  const [moodboardAP, setMoodboardAP] = useState(0);
  const [moodboardAPCapacity, setMoodboardAPCapacity] = useState(100);
  const [chroma, setChroma] = useState(0);
  const [profileData, setProfileData] = useState({});
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayType, setOverlayType] = useState(null);
  const [postLevel, setPostLevel] = useState(1);
  const [highestValueBadge, setHighestValueBadge] = useState(null);
  const [mostRecentBadges, setMostRecentBadges] = useState([]);
  const [isAnon, setIsAnon] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState(null);
  const [hasNotification, setHasNotification] = useState(true);
  const [target, setTarget] = useState({ id: post._id, contentType: post.type });
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { user, getToken } = useAuth();
  const notificationSocket = useRef(null);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      fetchBadges();
      fetchUserAP();
    }
  }, [isOpen]);

  useEffect(() => {
    if (post.type === 'MoodboardPost') {
      fetchAuthorUsername();
      fetchMoodboardAP();
    }
    setPostLevel(post.level || 1);
    setHighestValueBadge(getHighestValueBadge(post.givers));
    setMostRecentBadges(getMostRecentBadges(post.givers));
  }, [post]);

  useEffect(() => {
    const initNotificationSocket = async () => {
      try {
        if (user) {
          const token = await getToken();
          const response = await axios.get(`${API_BASE_URL}/notifications/status`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setHasNotification(response.data.hasNotification);

          notificationSocket.current = io(API_BASE_URL, {
            withCredentials: true,
            auth: { token },
          });

          notificationSocket.current.on('notificationUpdate', (data) => {
            setHasNotification(data.hasNotification);
            fetchUserAP();
          });

          return () => {
            notificationSocket.current.disconnect();
          };
        }
      } catch (error) {
        console.error('Error initializing notification socket:', error);
      }
    };

    initNotificationSocket();
  }, [user]);

  const fetchBadges = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/badges`);
      setBadges(response.data);
      calculateBadgePrices(response.data, level, apCapacity);
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  };

  const fetchUserAP = async () => {
    try {
      if (user) {
        const token = await getToken();
        const response = await axios.get(`${API_BASE_URL}/users/byUid/${user.uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setAP(data.ap);
        setAPCapacity(data.apCapacity);
        setChroma(data.chroma);
        setLevel(data.level);
      }
    } catch (error) {
      console.error('Error fetching user AP:', error);
    }
  };

  const fetchAuthorUsername = () => {
    setAuthorUsername(post.username || '');
  };

  const fetchMoodboardAP = () => {
    setMoodboardAP(post.ap || 0);
    setMoodboardAPCapacity(post.apCapacity || 100);
  };

  const calculateBadgePrices = (badges, userLevel, apCapacity) => {
    const prices = badges.reduce((acc, badge) => {
      if (badge.currency === 'AP') {
        let costPercentage = 0;
        for (let i = badge.costLevels.length - 1; i >= 0; i--) {
          if (userLevel >= badge.costLevels[i].levelThreshold) {
            costPercentage = badge.costLevels[i].percentage;
            break;
          }
        }
        const apCost = apCapacity * costPercentage;
        acc[badge._id] = `${Math.round((apCost / apCapacity) * 100)}%`;
      } else if (badge.currency === 'Chroma') {
        acc[badge._id] = `${badge.chromaCost} Chroma`;
      }
      return acc;
    }, {});
    setBadgePrices(prices);
  };

  const handlePurchaseBadge = async (badgeId) => {
    try {
      if (user) {
        const token = await getToken();
        const requestBody = {
          uid: user.uid,
          badgeId,
          targetId: target.id,
          contentType: target.contentType,
          isAnon,
          selectedSeal,
        };

        await axios.post(`${API_BASE_URL}/badges/purchase`, requestBody, {
          headers: { Authorization: `Bearer ${token}` },
        });

        await fetchUserAP();
        await refreshPost(post._id);
      }
    } catch (error) {
      console.error('Error purchasing badge:', error);
    }
  };

  const getHighestValueBadge = (givers) => {
    let highestChromaBadge = null;
    let highestChromaValue = 0;

    givers.forEach(giver => {
      giver.badges.forEach(badge => {
        if (badge.badgeId.currency === 'Chroma' && badge.badgeId.chromaCost > highestChromaValue) {
          highestChromaValue = badge.badgeId.chromaCost;
          highestChromaBadge = badge;
        }
      });
    });

    if (highestChromaBadge) {
      return highestChromaBadge;
    }

    let highestApBadge = null;
    let highestApValue = 0;

    givers.forEach(giver => {
      giver.badges.forEach(badge => {
        if (badge.badgeId.currency === 'AP') {
          const badgeValue = badge.badgeId.costLevels.reduce((max, level) => Math.max(max, level.percentage), 0);
          if (badgeValue > highestApValue) {
            highestApValue = badgeValue;
            highestApBadge = badge;
          }
        }
      });
    });

    return highestApBadge;
  };

  const getMostRecentBadges = (givers) => {
    let recentBadges = [];

    givers.forEach(giver => {
      recentBadges = recentBadges.concat(giver.badges);
    });

    return recentBadges.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3);
  };

  const openOverlay = async (type) => {
    if (type === 'MINI_PROFILE') {
      await fetchProfileData();
      if (hasNotification) {
        await clearNotifications();
      }
    }
    setOverlayType(type);
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setOverlayType(null);
  };

  const fetchProfileData = async () => {
    try {
      if (user) {
        const token = await getToken();
        const response = await axios.get(`${API_BASE_URL}/users/byUid/${user.uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setProfileData({
          username: data.username,
          level: data.level,
          achievements: data.achievements,
          seals: data.seals,
        });
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const clearNotifications = async () => {
    try {
      if (user) {
        const token = await getToken();
        await axios.post(`${API_BASE_URL}/notifications/clear`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setHasNotification(false);
      }
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  const handlePostClick = () => {
    navigate(`/post/${post._id}`);
  };

  const apPercentage = Math.min((ap / apCapacity) * 100, 100);
  const moodboardAPPercentage = Math.min((moodboardAP / moodboardAPCapacity) * 100, 100);

  return (
     <>
      {isOpen && post.type === 'MoodboardPost' && (
        <MoodboardDataContainer>
          <AuthorUsername>{authorUsername}</AuthorUsername>
          <MoodboardAPBarContainer>
            <APBar />
            <WhiteSlider style={{ width: `${100 - moodboardAPPercentage}%` }} />
          </MoodboardAPBarContainer>
        </MoodboardDataContainer>
      )}
      <PostLevelContainer>
        <PostLevelText>Lv. {postLevel}</PostLevelText>
      </PostLevelContainer>
      <PedestalBadgeContainer>
        <BadgePedestal onClick={() => openOverlay('LEADERBOARD')}>
          {highestValueBadge && (
            <BadgeImage src={highestValueBadge.badgeId.imageUrl} alt="Highest value badge" />
          )}
        </BadgePedestal>
      </PedestalBadgeContainer>
      <BadgeTrailContainer onClick={() => openOverlay('LEADERBOARD')}>
        {mostRecentBadges.map(badge => (
          <BadgeTrailImage key={badge._id} src={badge.badgeId.imageUrl} alt="Recent badge" />
        ))}
      </BadgeTrailContainer>
      {isOpen ? (
        <>
          <QuickBadgeStore>
            <BadgeItemsContainer>
              {badges.map((badge) => (
                <MiniBadgeImage
                  key={badge._id}
                  src={badge.imageUrl}
                  alt={badge.name}
                  onClick={() => handlePurchaseBadge(badge._id)}
                />
              ))}
            </BadgeItemsContainer>
            <Button onClick={() => openOverlay('BADGE_STORE')}>
              <ExpandLessIcon />
            </Button>
          </QuickBadgeStore>
          <Footer>
            <Button onClick={close}>
              <ArrowBackIcon />
            </Button>
            <Dock>
              <APBarContainer>
                <APBar />
                <WhiteSlider style={{ width: `${100 - apPercentage}%` }} />
                <DockMenu>
                  <Button onClick={() => openOverlay('AP_DETAILS')}>
                    {Math.round(apPercentage)}%
                  </Button>
                  <Button onClick={() => openOverlay('CHROMA_DETAILS')}>
                    <ChromaImage src="https://rwrldstaging.s3.us-east-2.amazonaws.com/100c18b7-8119-4c2c-b4eb-a8c21c177ca7-coin2.png" alt="Chroma" />
                    {chroma}
                  </Button>
                  <Button onClick={() => openOverlay('MINI_PROFILE')}>
                    <PersonIcon />
                    {hasNotification && <NotificationCircle />}
                  </Button>
                  <Button onClick={handlePostClick}>
                    <TextsmsIcon />
                  </Button>
                  {showReadMore && (
                    <TextButton onClick={handlePostClick}>
                      Read More
                    </TextButton>
                  )}
                </DockMenu>
              </APBarContainer>
            </Dock>
          </Footer>
        </>
      ) : (
        <Footer>
          <ToggleContainer>
            <Button onClick={open}>
              <CircleIcon />
            </Button>
          </ToggleContainer>
          {showReadMore && (
            <ReadMoreContainer>
              <TextButton onClick={handlePostClick}>
                Read More
              </TextButton>
            </ReadMoreContainer>
          )}
        </Footer>
      )}
      <OverlayComponent
        visible={overlayVisible}
        type={overlayType}
        data={{ ap, apCapacity, chroma, ...profileData, givers: post.givers }}
        target={target}
        onClose={closeOverlay}
        badges={badges}
        badgePrices={badgePrices}
        handlePurchaseBadge={handlePurchaseBadge}
        setAnonFlag={setIsAnon}
        isAnon={isAnon}
        selectedSeal={selectedSeal}
        onSealSelect={setSelectedSeal}
      />
    </>
  );
};

export default InteractionComponent;
