import React, { useContext } from 'react';
import styled from 'styled-components';
import { auth } from './firebaseInit';
import { signOut } from 'firebase/auth';
import { useAuth } from './AuthContext';
import ThemeContext from './ThemeContext';
import { useNavigate } from 'react-router-dom';

const DeleteAccount = () => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { getToken, clearToken } = useAuth();

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
      await confirmDeleteAccount();
    }
  };

  const confirmDeleteAccount = async () => {
    try {
      const token = await getToken();
      
      if (!token) {
        throw new Error('Failed to get a valid token');
      }

      // Delete the account on the backend
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        // If backend deletion is successful, proceed with client-side cleanup
        await cleanupClientSide();
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Failed to delete account on the server");
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert("An error occurred while deleting your account. Please try again.");
    }
  };

  const cleanupClientSide = async () => {
    try {
      // Clear all tokens and user data
      await clearToken();
      localStorage.removeItem('username');
      // Clear any other app-specific stored data
      // For example:
      // localStorage.removeItem('userRoles');
      // localStorage.clear();  // Be careful with this, as it clears ALL localStorage data

      // Sign out the user from Firebase
      await signOut(auth);

      // Navigate to Login page
      navigate('/login', { replace: true });

      alert("Your account has been successfully deleted.");
    } catch (error) {
      console.error('Error during client-side cleanup:', error);
      alert("Your account was deleted on the server, but there was an issue cleaning up locally. Please restart the app manually.");
    }
  };

  return (
    <Container>
      <WarningText theme={theme}>
        Your account will be deleted.
      </WarningText>
      <WarningText theme={theme}>
        If there are chatrooms for which you are the only host, please delete them before deleting your account.
      </WarningText>

      <Menu>
        <MenuItem onClick={() => navigate(-1)}>
          <MenuText theme={theme}>Close</MenuText>
        </MenuItem>
        <MenuItem onClick={handleDeleteAccount}>
          <DeleteText theme={theme}>Delete Account</DeleteText>
        </MenuItem>
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 30px;
  background-color: white;
`;

const WarningText = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-family: ${props => props.theme.text.fontFamily};
  font-size: ${props => props.theme.text.fontSize}px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0 20px;
`;

const MenuItem = styled.button`
  padding: 0 30px;
  background: none;
  border: none;
  cursor: pointer;
`;

const MenuText = styled.span`
  font-family: ${props => props.theme.text.fontFamily};
  font-size: ${props => props.theme.boldText.fontSize}px;
`;

const DeleteText = styled(MenuText)`
  color: red;
`;

export default DeleteAccount;