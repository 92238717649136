import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './AuthContext';
import PaymentForm from './PaymentForm';
import { useNavigate } from 'react-router-dom';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const APDetails = ({ data, onClose, getToken }) => {
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    fetchAchievements();
  }, []);

  const fetchAchievements = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/ap/achievements`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { achievements } = await response.json();
      setAchievements(achievements);
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  return (
    <OverlayContent>
      <Title>Appreciation Points (AP)</Title>
      <DetailText>Level: {data.level}</DetailText>
      
      <DetailText>Earn AP and Seals by completing achievements or participating in events.</DetailText>
      <ScrollArea>
        {achievements.map((achievement) => (
          <AchievementItem key={achievement.id}>
            <AchievementId>{achievement.id}</AchievementId>
            <AchievementDetails>
              {achievement.rewards.seal && (
                <SealImage src={achievement.rewards.seal.imageUrl} />
              )}
              <AchievementDescription>{achievement.description}</AchievementDescription>
            </AchievementDetails>
          </AchievementItem>
        ))}
      </ScrollArea>
      
      <CloseButton onClick={onClose}>Close</CloseButton>
    </OverlayContent>
  );
};

const ChromaDetails = ({ data, onClose, openChromaShop, getToken }) => {
  const [showConversion, setShowConversion] = useState(false);
  const conversionRate = 3; // 1 Chroma Ore = 3 USD

  const calculateUSDValue = (chromaOre) => {
    const usdValue = chromaOre * conversionRate;
    return usdValue.toFixed(2); // Round to 2 decimal places
  };

  const toggleConversion = () => {
    setShowConversion(!showConversion);
  };

  return (
    <OverlayContent>
      <Title>CHROMA</Title>
      <DetailText>Chroma: {data.chroma}</DetailText>
      <DetailText>Chroma Ore: {data.chromaOre}</DetailText>
      <DetailText>Once your balance exceeds the threshold, withdrawals will be available.</DetailText>
      <ConvertButton onClick={toggleConversion}>
        Convert Balance
      </ConvertButton>
      {showConversion && (
        <ConversionContainer>
          <ConversionText>
            ${calculateUSDValue(data.chromaOre)} USD
          </ConversionText>
        </ConversionContainer>
      )}
      <Button onClick={openChromaShop}>Refill Chroma</Button>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </OverlayContent>
  );
};

const MiniProfile = ({ data, onClose, selectedSeal, onSealSelect, getToken }) => {
  const [currentSeal, setCurrentSeal] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentSeal(selectedSeal);
  }, [selectedSeal]);

  const handleSealSelect = (seal) => {
    setCurrentSeal(seal);
    onSealSelect(seal);
  };

  return (
    <OverlayContent>
      <Title>Mini Profile</Title>
      <DetailText>{data.username}, receiving appreciation will grant Chroma Ore.</DetailText>
      <DetailText>Chroma Ore can be exchanged for currency. Open Chroma menu to see your balance.</DetailText>
      <DetailText>Level: {data.level}</DetailText>
      <Subtitle>Seals: select a seal to display with your acts of appreciation.</Subtitle>
      <ScrollArea>
        {data.seals.map((seal, index) => (
          <SealContainer key={index} onClick={() => handleSealSelect(seal)}>
            <SealImage src={seal.imageUrl} />
            <SealInfo>
              <DetailText>{seal.name} [{seal.count}]</DetailText>
              <SealDescription>{seal.description}</SealDescription>
            </SealInfo>
            {currentSeal && currentSeal._id === seal._id && (
              <SelectedText>Selected</SelectedText>
            )}
          </SealContainer>
        ))}
      </ScrollArea>
      <CloseButton onClick={onClose}>Close</CloseButton>
      <DeleteAccountButton onClick={() => {
        onClose();
        navigate('/delete-account');
      }}>
        Delete Account
      </DeleteAccountButton>
      
    </OverlayContent>
  );
};

const BadgeStore = ({ badges, badgePrices, onClose, handlePurchaseBadge, setAnonFlag, isAnon, openChromaShop, getToken }) => {
  const apBadges = badges
    .filter(badge => badge.currency === 'AP')
    .sort((a, b) => parseFloat(badgePrices[a._id]) - parseFloat(badgePrices[b._id]));

  const chromaBadges = badges
    .filter(badge => badge.currency === 'Chroma')
    .sort((a, b) => parseFloat(badgePrices[a._id]) - parseFloat(badgePrices[b._id]));


  return (
    <OverlayContent>
      <Title>VITRINE</Title>
      <BadgeCategory>
        <CategoryTitle>AP Badges</CategoryTitle>
        <BadgeStoreContainer>
          {apBadges.map((badge) => (
            <BadgeItem key={badge._id} onClick={() => handlePurchaseBadge(badge._id)}>
              <BadgeImage src={badge.imageUrl} alt={badge.name} />
              <BadgeName>{badge.name}</BadgeName>
              <PriceText>{badgePrices[badge._id]}</PriceText>
            </BadgeItem>
          ))}
        </BadgeStoreContainer>
      </BadgeCategory>

      <BadgeCategory>
        <CategoryTitle>Chroma Badges</CategoryTitle>
        <BadgeStoreContainer>
          {chromaBadges.map((badge) => (
            <BadgeItem key={badge._id} onClick={() => handlePurchaseBadge(badge._id)}>
              <BadgeImage src={badge.imageUrl} alt={badge.name} />
              <BadgeName>{badge.name}</BadgeName>
              <PriceText>{badgePrices[badge._id]}</PriceText>
            </BadgeItem>
          ))}
        </BadgeStoreContainer>
      </BadgeCategory>
      <AnonToggleContainer>
        <DetailText>Appreciate Anonymously:</DetailText>
        <Switch 
          checked={isAnon} 
          onChange={(e) => setAnonFlag(e.target.checked)}
        />
      </AnonToggleContainer>
      <Button onClick={openChromaShop}>Refill Chroma</Button>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </OverlayContent>
  );
};

const ChromaShop = ({ bundles, onClose, updateUserChroma, getToken }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [paypalOrderId, setPayPalOrderId] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleBuy = async (bundle) => {
    try {
      const token = await getToken();

      // Create Stripe Payment Intent
      const response = await fetch(`${API_BASE_URL}/chroma-shop/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ bundleId: bundle._id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClientSecret(data.clientSecret);

      // Create PayPal Order
      try {
    const paypalResponse = await fetch(`${API_BASE_URL}/chroma-shop/create-paypal-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ bundleId: bundle._id }),
    });

    if (!paypalResponse.ok) {
      throw new Error(`HTTP error! status: ${paypalResponse.status}`);
    }

    const paypalData = await paypalResponse.json();
    setPayPalOrderId(paypalData.id);
  } catch (paypalError) {
    console.error('Error creating PayPal order:', paypalError);
    // Continue without PayPal
  }

  setSelectedBundle(bundle);
} catch (error) {
  console.error('Error creating PaymentIntent:', error);
}
};

  const handleSuccessfulPayment = async (paymentIntentId) => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/chroma-shop/purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ bundleId: selectedBundle._id, paymentIntentId, platform: 'web' }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Purchase successful, updating user chroma:', data.chroma);
      updateUserChroma(data.chroma);
      alert('Purchase successful! Chroma updated. Receipt will be sent to your email');
      onClose();
    } catch (error) {
      console.error('Error purchasing Chroma bundle:', error);
      alert('Purchase failed. Please try again.');
    }
  };

  const handleSuccessfulPayPalPayment = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
  
    try {
      const token = await getToken();
      console.log('Token:', token);
      console.log('PayPal Order ID:', paypalOrderId);
      console.log('Selected Bundle ID:', selectedBundle._id);
  
      const response = await fetch(`${API_BASE_URL}/chroma-shop/capture-paypal-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ orderID: paypalOrderId, bundleId: selectedBundle._id }),
      });
  
      console.log('Response Status:', response.status);
      console.log('Response Headers:', response.headers);
  
      if (response.status === 400) {
        const errorData = await response.json();
        console.error('Error response from server:', errorData);
        throw new Error(`HTTP error! status: ${response.status}, error: ${errorData.error}, details: ${errorData.details}`);
      } else if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from server:', errorData);
        throw new Error(`HTTP error! status: ${response.status}, error: ${errorData.error}, details: ${errorData.details}`);
      } else {
        const data = await response.json();
        console.log('Purchase successful, updating user chroma:', data.chroma);
        updateUserChroma(data.chroma);
        alert('Purchase successful!');
        onClose();
        setPayPalOrderId(null); // Clear the paypalOrderId state after a successful purchase
      }
    } catch (error) {
      console.error('Error capturing PayPal order:', error);
      alert(`Purchase failed. Please try again. Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };
  
  
  
  

  return (
    <OverlayContent>
      <Title>Chroma Shop - USD</Title>
      {clientSecret && selectedBundle && (
      //{clientSecret && paypalOrderId ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentForm
            clientSecret={clientSecret}
            paypalOrderId={paypalOrderId}
            onSuccessfulPayment={handleSuccessfulPayment}
            onSuccessfulPayPalPayment={handleSuccessfulPayPalPayment}
          />
        </Elements>
      )}
        <BundleContainer>
        {!clientSecret && bundles.map((bundle) => (
            <Bundle key={bundle._id}>
              <BundleHeader>
                <BundleImage src={bundle.imageUrl} />
                <BundleText>{bundle.name}</BundleText>
              </BundleHeader>
              {/*<BundleText>Chroma: {bundle.amount}</BundleText>*/}
              <BundleText>${bundle.price}</BundleText>
              <BuyButton onClick={() => handleBuy(bundle)}>
                <BuyButtonText>Buy</BuyButtonText>
              </BuyButton>
            </Bundle>
        ))}
        </BundleContainer>
        <BundleText>For larger bespoke order, please contact r-wrld.com/support</BundleText>
      <BackButton onClick={onClose}>Back</BackButton>
    </OverlayContent>
  );
};

const Leaderboard = ({ givers, onClose, target }) => {
  const allBadges = useMemo(() => {
    return givers.flatMap(giver => 
      giver.badges.map(badge => ({
        ...badge,
        giver: { 
          username: giver.giverUsername, 
          isAnon: giver.isAnon, 
          seal: giver.seal 
        }
      }))
    );
  }, [givers]);

  const getHighestValueBadges = (badges) => {
    const sortedBadges = badges.sort((a, b) => {
      if (a.badgeId.currency === 'Chroma' && b.badgeId.currency === 'Chroma') {
        return b.badgeId.chromaCost - a.badgeId.chromaCost;
      } else if (a.badgeId.currency === 'AP' && b.badgeId.currency === 'AP') {
        const aValue = Math.max(...a.badgeId.costLevels.map(level => level.percentage));
        const bValue = Math.max(...b.badgeId.costLevels.map(level => level.percentage));
        return bValue - aValue;
      } else if (a.badgeId.currency === 'Chroma') {
        return -1;
      } else {
        return 1;
      }
    });
    return sortedBadges.slice(0, 3);
  };

  const topBadges = useMemo(() => getHighestValueBadges(allBadges), [allBadges]);
  const recentBadges = useMemo(() => [...allBadges].sort((a, b) => new Date(b.date) - new Date(a.date)), [allBadges]);

  const truncateUsername = (username, maxLength = 15) => {
    return username.length > maxLength ? username.slice(0, maxLength) + '...' : username;
  };

  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const time = new Date(timestamp);
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}m`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}h`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)}d`;
    }
  };

  return (
    <OverlayContent>
      <Title>Ledger</Title>
      
      <Podium>
        {topBadges.map((badge, index) => (
          <PodiumItem key={badge._id}>
            <PodiumRank>{index + 1}</PodiumRank>
            <BadgeImage src={badge.badgeId.imageUrl} alt={badge.badgeId.name} />
            <PodiumUsername>
              {badge.giver.isAnon ? 'Anonymous' : truncateUsername(badge.giver.username)}
            </PodiumUsername>
            <BadgeName>{badge.badgeId.name}</BadgeName>
            {badge.giver.seal && badge.giver.seal !== 'No Seal' && (
              <SealImage src={badge.giver.seal} alt="Seal" />
            )}
          </PodiumItem>
        ))}
      </Podium>

      <BadgeList>
        {recentBadges.map((badge) => (
          <LeaderboardItem key={badge._id}>
            <BadgeImage src={badge.badgeId.imageUrl} alt={badge.badgeId.name} />
            <BadgeInfo>
              <Username>
                {badge.giver.isAnon ? 'Anonymous' : badge.giver.username}
              </Username>
              <BadgeName>{badge.badgeId.name}</BadgeName>
              <EarnedDate>
                {getRelativeTime(badge.date)}
              </EarnedDate>
            </BadgeInfo>
            {badge.giver.seal && badge.giver.seal !== 'No Seal' && (
              <SealImage src={badge.giver.seal} alt="Seal" />
            )}
          </LeaderboardItem>
        ))}
      </BadgeList>

      <CloseButton onClick={onClose}>Close</CloseButton>
    </OverlayContent>
  );
};

const Options = ({ target, data, onClose, getToken }) => {
  const navigate = useNavigate();
  const [isBlocked, setIsBlocked] = useState(false);
  const isOwnContent = target.username === data.username;
  const targetText = target?.username ? `Options for ${target.username}'s ${target.contentType?.toLowerCase()}` : 'Options';
  const isPost = target.contentType === 'TextEditorPost' || target.contentType === 'MoodboardPost';

  useEffect(() => {
    checkIfBlocked();
  }, []);

  const checkIfBlocked = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/relational-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setIsBlocked(userData.blockedUsers.includes(target?.username));
      }
    } catch (error) {
      console.error('Error checking block status:', error);
    }
  };


  const handleBlock = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/moderation/block`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          targetUsername: target.username,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to block user: ${response.statusText}`);
      }

      alert("User blocked successfully");
      setIsBlocked(true);
      onClose();
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  };

  const handleUnblock = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/moderation/unblock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          targetUsername: target.username,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to unblock user: ${response.statusText}`);
      }

      alert("User unblocked successfully");
      setIsBlocked(false);
      onClose();
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const token = await getToken();
      const route = target.contentType === 'Comment' ? 'comments' : 
                    (target.contentType === 'TextEditorPost' ? 'text-editor-posts' : 'moodboard-posts');
      
      const response = await fetch(`${API_BASE_URL}/${route}/${target.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete ${target.contentType.toLowerCase()}: ${response.statusText}`);
      }

      onClose();
      navigate('/editor-feed');
    } catch (error) {
      console.error('Error deleting content:', error);
    }
  };

  const handleHide = async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${API_BASE_URL}/moderation/hide`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          contentId: target.id,
          contentType: target.contentType,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to hide ${target.contentType.toLowerCase()}: ${response.statusText}`);
      }

      alert("Content hidden successfully. You may need to refresh the page for changes to take effect");
      onClose();
    } catch (error) {
      console.error('Error hiding content:', error);
    }
  };

  return (
    <OverlayContent>
      <Title>{targetText}</Title>
      {isOwnContent ? (
        isPost && (
          <OptionButton onClick={handleDelete}>
            Delete
          </OptionButton>
        )
      ) : (
        <>
          {target.contentType !== 'Chatroom' && (
            <OptionButton onClick={handleHide}>
              I don't want to see this content
            </OptionButton>
          )}
          {isBlocked ? (
            <OptionButton onClick={handleUnblock}>
              Unblock User
            </OptionButton>
          ) : (
            <OptionButton onClick={handleBlock}>
              Block User
            </OptionButton>
          )}
          <OptionButton>
            To report, send a message in a support chatroom or contact rwrld@djinnsandangels.com
          </OptionButton>
        </>
      )}
      <CloseButton onClick={onClose}>Close</CloseButton>
    </OverlayContent>
  );
};


const OverlayComponent = ({ visible, type, data, onClose, badges, badgePrices, handlePurchaseBadge, setAnonFlag, isAnon, selectedSeal, onSealSelect, target, updateUserChroma }) => {
  const { user, loading, getToken } = useAuth();
  const [nestedOverlayType, setNestedOverlayType] = useState(null);
  const [previousOverlayType, setPreviousOverlayType] = useState(null);
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    if (nestedOverlayType === 'CHROMA_SHOP') {
      const fetchBundles = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/chroma-shop?platform=web`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setBundles(data);
        } catch (error) {
          console.error('Error fetching Chroma bundles:', error);
        }
      };
      fetchBundles();
    }
  }, [nestedOverlayType]);

  const handleOpenChromaShop = () => {
    setPreviousOverlayType(type);
    setNestedOverlayType('CHROMA_SHOP');
  };

  const handleCloseNestedOverlay = () => {
    setNestedOverlayType(null);
    if (previousOverlayType) {
      type = previousOverlayType;
      setPreviousOverlayType(null);
    } else {
      onClose();
    }
  };
  if (!visible && !nestedOverlayType) return null;

  let content;
  if (nestedOverlayType === 'CHROMA_SHOP') {
    content = (
      <ChromaShop 
        bundles={bundles} 
        onClose={handleCloseNestedOverlay} 
        updateUserChroma={updateUserChroma}
        getToken={getToken}
      />
    );
  } else if (type === 'AP_DETAILS') {
    content = <APDetails data={data} onClose={onClose} getToken={getToken} />;
  } else if (type === 'CHROMA_DETAILS') {
    content = <ChromaDetails data={data} onClose={onClose} openChromaShop={handleOpenChromaShop} getToken={getToken} />;
  } else if (type === 'MINI_PROFILE') {
    content = <MiniProfile data={data} onClose={onClose} selectedSeal={selectedSeal} onSealSelect={onSealSelect} getToken={getToken} />;
  } else if (type === 'BADGE_STORE') {
    content = <BadgeStore badges={badges} badgePrices={badgePrices} onClose={onClose} handlePurchaseBadge={handlePurchaseBadge} setAnonFlag={setAnonFlag} isAnon={isAnon} openChromaShop={handleOpenChromaShop} getToken={getToken} />;
  } else if (type === 'LEADERBOARD') {
    content = <Leaderboard givers={data.givers} onClose={onClose} target={target} getToken={getToken} />;
  } else if (type === 'OPTIONS') {
    content = <Options target={target} data={data} onClose={onClose} getToken={getToken} />;
  }

  return (
    <OverlayContainer>
      {content}
    </OverlayContainer>
  );
};

// Styled components
const OverlayContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: pink;
  z-index: 1000;
  //max-width: 860px;
`;

const OverlayContent = styled.div`
max-width: 740px;
  width: 80%;
  padding: 20px;
  background-color: white;
  border: 2px solid black;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin: 5px 0;
`;

const Subtitle = styled.h3`
  //margin-top: 25px;
  font-size: 14px;
`;

const DetailText = styled.p`
  margin: 5px 0;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
`;
const BackButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const BadgeCategory = styled.div`
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h3`
  margin-bottom: 10px;
`;

const BadgeStoreContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  max-height: 250px;
`;

const BadgeItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  align-items: center;
  cursor: pointer;
`;

const BadgeImage = styled.img`
  width: 60px;
  height: 60px;
`;

const PriceText = styled.p`
  text-align: center;
  //font-size: 14px;
  margin: 0px 0;
`;

const AnonToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-vertical: 20px;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  //margin-horizontal: 5px;
`;

const SealContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
`;

const SealInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const SealDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin: 2px 0;
`;

const SealImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const SelectedText = styled.span`
  color: green;
  font-weight: bold;
`;

const ScrollArea = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;
`;

const BundleContainer = styled.div`
  display: flex;
  //flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  max-height: 500px;
`;

const Bundle = styled.div`
  //display: flex;
  //flex-direction: column;
  width: 125px;
  margin: 10px 40px;
  align-items: center;
  text-align: center;
`;

const BundleHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  //margin-bottom: 0px;
`;

const BundleImage = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

const BundleText = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
`;

const BuyButton = styled.button`
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const BuyButtonText = styled.span`
  text-align: center;
`;

const AchievementItem = styled.div`
  //margin-bottom: 10px;
  //padding: 5px;
  border-bottom: 1px solid;
`;

const AchievementId = styled.p`
  font-weight: bold;
  margin: 10px 0;
`;

const AchievementDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  //padding-vertical: 5px;
`;

const AchievementDescription = styled.p`
  flex: 1;
  margin: 0;
`;

const ConvertButton = styled.button`
  margin-vertical: 10px;
  //margin-left: 5px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const ConversionContainer = styled.div`
  margin-vertical: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  align-items: center;
`;

const ConversionText = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const Podium = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 25px;
  margin-bottom: 15px;
`;

const PodiumItem = styled.div`
  //align-items: center;
  //margin: 0 45px;
  text-align: center;
`;

const PodiumRank = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

const PodiumUsername = styled.p`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BadgeList = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const LeaderboardItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  //background-color: pink;
  
`;

const BadgeInfo = styled.div`
  flex: 1;
  margin-left: 10px;
  //background-color: pink;
`;

const Username = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

const BadgeName = styled.p`
  font-style: italic;
  //font-size: 14px;
  margin: 10px 0;
`;

const EarnedDate = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0;
`;

const OptionButton = styled.button`
  display: flex;
  //flex-wrap: wrap;
  margin: 10px;
  padding: 15px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 80%;
`;

const DeleteAccountButton = styled.p`
  margin-top: 20px;
  //background-color: #f44336;
  color: #f44336;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

export default OverlayComponent;
