import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const PolicyLink = styled.a`
  font-size: 18px;
  color: #007BFF;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

function Privacy() {
  // Replace this URL with your actual privacy policy URL
  const privacyPolicyUrl = "https://docs.google.com/document/d/1V49QC7R89coIN8Rxc-Qdkd-hBv4xngLiAPVmOHuL7RM/edit?usp=sharing";

  return (
    <Container>
      <Title>Privacy Policy</Title>
      <PolicyLink href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
        Click here to view our Privacy Policy
      </PolicyLink>
    </Container>
  );
}

export default Privacy;