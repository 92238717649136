// TokenManager.js

import { auth } from './firebaseInit';

let currentToken = null;
let tokenExpirationTime = null;

const TOKEN_EXPIRATION_BUFFER = 5 * 60 * 1000; // 5 minutes in milliseconds

export const getToken = async (forceRefresh = false) => {
  console.log('getToken called with forceRefresh:', forceRefresh);
  const user = auth.currentUser;
  if (!user) {
    console.log('No user logged in');
    return null;
  }

  console.log('Current user:', user.uid);
  const now = Date.now();

  console.log('Current token:', currentToken);
  console.log('Token expiration time:', tokenExpirationTime);

  // Check if we need to refresh the token
  if (forceRefresh || !currentToken || !tokenExpirationTime || now >= tokenExpirationTime - TOKEN_EXPIRATION_BUFFER) {
    console.log('Refreshing token');
    try {
      const newToken = await user.getIdToken(true);
      console.log('New token obtained');
      const decodedToken = JSON.parse(atob(newToken.split('.')[1]));
      
      currentToken = newToken;
      tokenExpirationTime = decodedToken.exp * 1000; // Convert to milliseconds

      console.log('Token refreshed and stored');
      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  }

  console.log('Returning current token');
  return currentToken;
};

export const clearToken = () => {
  console.log('Clearing token');
  currentToken = null;
  tokenExpirationTime = null;
  console.log('Token cleared');
};

console.log('TokenManager module loaded');