import { initializeApp, getApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import firebaseConfig from './firebaseConfig';

let appInitialized = false;
let app;
let auth;

const initializeFirebase = () => {
  if (!appInitialized) {
    try {
      app = getApp();
      console.log('Using existing Firebase app');
    } catch (error) {
      app = initializeApp(firebaseConfig);
      console.log('Firebase app initialized');
    }
    auth = getAuth(app);
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to local');
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });
    appInitialized = true;
  }
  return auth;
};

export { initializeFirebase, app, auth };